import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import EmployeeCard from "./EmployeeCard";
import CalendarStrip from "./CalendarStrip";
import { BsChevronLeft } from "react-icons/bs";
import dayjs from "dayjs";
import { FiAlertOctagon, FiArrowLeft } from "react-icons/fi";
import UnscheduledStopCard from "./UnscheduledStopCard";
import { AiOutlineSearch } from "react-icons/ai";
import { BiCog } from "react-icons/bi";
import RescheduledStopCard from "./RescheduledStopCard";

const SideMenu = (props) => {
  const selectedRoute = props.selectedRoute;
  const selectedBusiness = props.selectedBusiness;
  const [unscheduledStops, setUnscheduledStops] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [stopViewDisplay, setStopViewDisplay] = useState(false);
  const [stopViewData, setStopViewData] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [unscheduledSearchTerm, setUnscheduledSearchTerm] = useState("");
  const [dragId, setDragId] = useState();
  const [allEmployees, setAllEmployees] = useState([]);
  const db = firebase.firestore();
  const [menuOption, setMenuOption] = useState("all");
  const [daysWithStops, setDaysWithStops] = useState([]);
  const [jobStopDates, setJobStopDates] = useState([]);
  const [rescheduledStops, setRescheduledStops] = useState([]);

  const fetchEmployeeData = async (value) => {
    const token = await firebase.auth().currentUser.getIdToken();

    const res = await fetch(
      "https://us-central1-symbri-production.cloudfunctions.net/fetchRouteDots",
      {
        method: "POST",
        body: JSON.stringify({
          token: token,
          value: value,
        }),
      }
    );

    const data = await res.json();

    if (data) {
      return data.allStops;
    }
  };

  const processDots = async (allEmployees) => {
    // using promise all to get all the data at once

    const allData = await Promise.all(
      allEmployees.map(async (value) => {
        return fetchEmployeeData(value);
      })
    );

    let daysOfWeeksWithStops = [];

    let daysWithJobStops = [];

    for (let index = 0; index < allData.length; index++) {
      const employee = allData[index];
      const recdays = employee
        .filter((a) => a.type !== "Job Stop")
        .map((value) => value.dayOfTheWeek);
      const jobdates = employee
        .filter((a) => a.type === "Job Stop")
        .map((a) => a.stopDate);

      for (let index = 0; index < recdays.length; index++) {
        const day = recdays[index];
        if (!daysOfWeeksWithStops.includes(day)) {
          daysOfWeeksWithStops.push(day);
        }
      }

      for (let index = 0; index < jobdates.length; index++) {
        const date = jobdates[index];
        if (!daysWithJobStops.includes(date)) {
          daysWithJobStops.push(date);
        }
      }
    }
    setDaysWithStops(daysOfWeeksWithStops);
    setJobStopDates(daysWithJobStops);
  };

  useEffect(() => {
    setAllEmployees(props.allEmployees);
    processDots(props.allEmployees);
  }, [props.allEmployees]);

  useEffect(() => {
    setUnscheduledStops(props.unscheduledMarkers);
    console.log(props.unscheduledMarkers);
  }, [props.unscheduledMarkers]);

  useEffect(() => {
    console.log(props.rescheduledMarkers);
    setRescheduledStops(props.rescheduledMarkers);
  }, [props.rescheduledMarkers]);

  const handleDeleteEmployee = (value, totalCount) => {
    if (totalCount === 0) {
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Routes")
        .doc(selectedRoute.routeId)
        .collection("Employees")
        .doc(value.uid)
        .delete();
    } else {
      alert(
        "Cannot delete an employee with stops! Please remove all stops and try again!"
      );
    }
  };

  const handleSelectDate = (date) => {
    props.setRouteStartDate(dayjs(date).format("MM/DD/YYYY"));
    setSelectedDate(dayjs(date).format("MM/DD/YYYY"));
  };

  const handleGoBackDayView = () => {
    props.setDayViewDisplay(false);
    props.setDayViewData({});
  };

  const handleGoBackStopView = () => {
    setStopViewData({});
    setStopViewDisplay(false);
  };

  const handleCheckedUnscheduledStop = (stop) => {
    let currentSelectedUnscheduledStops = props.selectedUnscheduledStops;
    console.log(stop);
    currentSelectedUnscheduledStops.push(stop);
    props.setSelectedUnscheduledStops(currentSelectedUnscheduledStops);
    props.setRefresh(Math.random(0, 2));
  };

  const handleUnCheckedUnscheduledStop = (stop) => {
    let currentSelectedUnscheduledStops = props.selectedUnscheduledStops;
    const indexOfStop = currentSelectedUnscheduledStops.findIndex(
      (a) => a.stopId === stop.stopId
    );

    currentSelectedUnscheduledStops.splice(indexOfStop, 1);
    props.setSelectedUnscheduledStops(currentSelectedUnscheduledStops);
    props.setRefresh(Math.random(0, 2));
  };

  const handleSaveNewData = (orderArr) => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Workers")
      .doc(props.dayViewData.employee.uid)
      .collection("Route")
      .doc(dayjs(selectedDate).format("dddd"))
      .get()
      .then((documentSnapshot) => {
        if (documentSnapshot.exists) {
          const docData = documentSnapshot.data();
          let currentCustomRouteOrders = docData.customRouteOrders;
          let indexOfExisting = currentCustomRouteOrders.findIndex(
            (a) => a.date === dayjs(selectedDate).format("MM/DD/YYYY")
          );

          if (indexOfExisting !== -1) {
            currentCustomRouteOrders.splice(indexOfExisting, 1);
          }
          currentCustomRouteOrders.push({
            date: dayjs(selectedDate).format("MM/DD/YYYY"),
            order: orderArr,
          });

          db.collection("Businesses")
            .doc(selectedBusiness)
            .collection("Workers")
            .doc(props.dayViewData.employee.uid)
            .collection("Route")
            .doc(dayjs(selectedDate).format("dddd"))
            .update({
              customRouteOrders: currentCustomRouteOrders,
              checkDistanceTime: true,
            })
            .catch((e) => console.log(e));
        }
      })
      .catch((e) => console.log(e));
  };

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const dragIndex = Number(dragId);
    const dropIndex = Number(ev.currentTarget.id);

    // ensure dragIndex and dropIndex are valid numbers
    if (isNaN(dragIndex) || isNaN(dropIndex)) {
      console.error("Invalid drag or drop index");
      return;
    }

    let currentArrOfStops = props.dayViewData.data.sort(
      (a, b) =>
        props.customRouteOrder.indexOf(a.tempStopId ? a.tempStopId : a.stopId) -
        props.customRouteOrder.indexOf(b.tempStopId ? b.tempStopId : b.stopId)
    );

    let value = currentArrOfStops[dragIndex].stopId;
    let currentOrder = [];

    for (let index = 0; index < currentArrOfStops.length; index++) {
      const stop = currentArrOfStops[index];

      currentOrder.push(stop.tempStopId ? stop.tempStopId : stop.stopId);
    }

    currentOrder.splice(dragIndex, 1);

    currentOrder.splice(dropIndex, 0, value);
    props.handleUpdateRouteOrder(currentOrder);
    handleSaveNewData(currentOrder);
    setRefresh(Math.random(0, 2));
  };

  return (
    <div>
      <div
        style={{
          width: 340,
          position: "absolute",
          zIndex: 2000,
          marginBottom: "10px",
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
          height: "88vh",
          backgroundColor: "white",
        }}
      >
        <div
          style={{ borderBottom: "1px solid #D9D9D9", minHeight: 48 }}
          className="flex items-center relative"
        >
          <div
            onClick={() => setMenuOption("all")}
            style={{
              borderBottom: menuOption === "all" ? "3px solid black" : "none",
              color: menuOption === "all" ? "black" : "gray",
              marginBottom: "-15px",
              width: 40,
              display: "flex",
              justifyContent: "center",
            }}
            className="ml-4 cursor-pointer pb-1"
          >
            <div className="pb-1">All</div>
          </div>
          <div
            style={{
              borderBottom:
                menuOption === "rescheduled" ? "3px solid black" : "none",
              color: menuOption === "rescheduled" ? "black" : "gray",
              marginBottom: "-15px",
            }}
            onClick={() => setMenuOption("rescheduled")}
            className="ml-4 cursor-pointer pb-1"
          >
            <div className="pb-1">Rescheduled</div>
          </div>
          <div
            style={{
              borderBottom:
                menuOption === "unscheduled" ? "3px solid black" : "none",
              color: menuOption === "unscheduled" ? "black" : "gray",
              marginBottom: "-15px",
            }}
            onClick={() => setMenuOption("unscheduled")}
            className="ml-4 cursor-pointer pb-1"
          >
            <div className="pb-1">Unscheduled</div>
          </div>
        </div>

        {!props.dayViewDisplay && menuOption === "all" && (
          <CalendarStrip
            selectedDate={selectedDate}
            handleSelectDate={handleSelectDate}
            disabled={selectedRoute.active}
            serviceLocationsInRoute={props.serviceLocationsInRoute}
            daysWithStops={daysWithStops}
            jobStopDates={jobStopDates}
          />
        )}
        <div
          className="hideScroll"
          style={{
            maxHeight: "70vh",
            overflowY: "scroll",
          }}
        >
          {!props.dayViewDisplay &&
            menuOption === "all" &&
            allEmployees.map((value) => (
              <EmployeeCard
                handleDeleteEmployee={handleDeleteEmployee}
                value={value}
                handleEditMode={props.handleEditMode}
                editModeDay={props.editModeDay}
                selectedBusiness={selectedBusiness}
                selectedRoute={selectedRoute}
                handleToggleHiddenDay={props.handleToggleHiddenDay}
                selectedDate={selectedDate}
                handleViewDay={props.handleViewDay}
                key={value.uid}
                handleCancelEditMode={props.handleCancelEditMode}
                handleOpenReschedule={props.handleOpenReschedule}
                rescheduledStops={props.rescheduledStops}
                setDayData={props.setDayData}
                dayData={props.dayData}
                forceEmployeeCardsReload={props.forceEmployeeCardsReload}
                markersDataForProp={props.markersDataForProp}
                hiddenEmployees={props.hiddenEmployees}
              />
            ))}
        </div>
        {!props.dayViewDisplay && menuOption === "rescheduled" && (
          <div style={{ width: "100%" }}>
            <div
              style={{
                border: "1px solid #D9D9D9",
                width: "88%",
                margin: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 40,
                borderRadius: 5,
                marginTop: 14,
              }}
            >
              <input
                value={unscheduledSearchTerm}
                placeholder="Search"
                onChange={(change) =>
                  setUnscheduledSearchTerm(change.target.value)
                }
                style={{ width: "100%", outline: "none", paddingLeft: 12 }}
              />
              <div style={{ marginRight: "2%", fontSize: "1rem" }}>
                <AiOutlineSearch />
              </div>
            </div>
            <div
              className="hideScroll"
              style={{
                height: "70vh",
                overflowY: "scroll",
              }}
              class="routeBuilderEditSideMenuScroll"
            >
              {rescheduledStops
                .filter((a) =>
                  unscheduledSearchTerm.length === 0
                    ? true
                    : a.customerName
                        .toLowerCase()
                        .includes(unscheduledSearchTerm.toLowerCase())
                )
                .map((value) => (
                  <RescheduledStopCard value={value} />
                ))}
            </div>
          </div>
        )}
        {!props.dayViewDisplay && menuOption === "unscheduled" && (
          <div style={{ width: "100%" }}>
            <div
              style={{
                border: "1px solid #D9D9D9",
                width: "88%",
                margin: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 40,
                borderRadius: 5,
                marginTop: 14,
              }}
            >
              <input
                value={unscheduledSearchTerm}
                placeholder="Search"
                onChange={(change) =>
                  setUnscheduledSearchTerm(change.target.value)
                }
                style={{ width: "100%", outline: "none", paddingLeft: 12 }}
              />
              <div style={{ marginRight: "2%", fontSize: "1rem" }}>
                <AiOutlineSearch />
              </div>
            </div>
            <div
              className="hideScroll"
              style={{
                height: "70vh",
                overflowY: "scroll",
              }}
              class="routeBuilderEditSideMenuScroll"
            >
              {unscheduledStops
                .filter((a) => {
                  if (unscheduledSearchTerm.length === 0) return true;

                  let nameToCheck = "";

                  if (a.customer) {
                    // If there's a customer object, prefer customer.name if available, otherwise use customer.customerName
                    nameToCheck = a.customer.name
                      ? a.customer.name
                      : a.customer.customerName || "";
                  } else if (a.customerData) {
                    // If there's no a.customer but a.customerData is always a fallback with name
                    nameToCheck = a.customerData.name;
                  }

                  return nameToCheck
                    .toLowerCase()
                    .includes(unscheduledSearchTerm.toLowerCase());
                })

                .map((value) => (
                  <UnscheduledStopCard
                    setSelectedUnscheduledStop={
                      props.setSelectedUnscheduledStop
                    }
                    selectedUnscheduledStop={props.selectedUnscheduledStop}
                    selectedUnscheduledStops={props.selectedUnscheduledStops}
                    value={value}
                    handleViewDetails={props.handleViewDetails}
                    handleUnCheckedUnscheduledStop={
                      handleUnCheckedUnscheduledStop
                    }
                    handleCheckedUnscheduledStop={handleCheckedUnscheduledStop}
                    handleAddUnscheduledStopToDay={() =>
                      props.handleAddUnscheduledStopToDay(value, allEmployees)
                    }
                  />
                ))}
            </div>
          </div>
        )}

        {props.dayViewDisplay && (
          <div
            style={{
              backgroundColor: "#F9F9F9",
              height: "90%",
              marginTop: -2,
              paddingTop: 6,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: 44,
              }}
            >
              <div
                onClick={() => handleGoBackDayView()}
                style={{
                  marginLeft: "4%",
                  cursor: "pointer",
                }}
              >
                <FiArrowLeft color="black" size={18} />
              </div>

              <div
                onClick={() => handleGoBackDayView()}
                style={{ marginLeft: "2%", cursor: "pointer" }}
              >
                Back
              </div>
              <div
                style={{
                  display: "flex",
                  color: "white",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginLeft: "auto",
                  marginRight: "8%",
                }}
              >
                <div style={{ marginLeft: "4%", color: "black" }}>
                  {dayjs(selectedDate).format("dddd")}
                </div>
                <div style={{ color: "black", marginLeft: "4%" }}>
                  {dayjs(selectedDate).format("MM/DD/YYYY")}
                </div>
              </div>
            </div>

            <div className="flex items-center mt-2">
              <div style={{ marginLeft: "6%" }}>
                <img
                  className="inline-block h-10 w-10 rounded-full"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef"
                  }
                />
              </div>
              <div
                style={{
                  marginLeft: "3%",
                  color: "black",
                  fontSize: "0.88rem",
                  fontWeight: "600",
                }}
              >
                <div>
                  {props.dayViewData.employee.name.replace(
                    /(^\w{1})|(\s+\w{1})/g,
                    (letter) => letter.toUpperCase()
                  )}
                </div>
              </div>
            </div>

            <div
              className="hideScroll"
              style={{ maxHeight: "80%", overflowY: "scroll", marginTop: 12 }}
            >
              {props.dayViewData &&
                props.dayViewData.data &&
                props.dayViewData.data
                  .sort(
                    (a, b) =>
                      props.customRouteOrder.indexOf(
                        a.tempStopId ? a.tempStopId : a.stopId
                      ) -
                      props.customRouteOrder.indexOf(
                        b.tempStopId ? b.tempStopId : b.stopId
                      )
                  )
                  .map((value, index) => (
                    <div
                      onDragOver={(ev) => ev.preventDefault()}
                      onDragStart={handleDrag}
                      onDrop={handleDrop}
                      draggable
                      id={index}
                      key={value.tempStopId ? value.tempStopId : value.stopId}
                      stopId={
                        value.tempStopId ? value.tempStopId : value.stopId
                      }
                      style={{
                        minHeight: 60,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "4%",
                          fontSize: "0.8rem",
                          width: "7%",
                        }}
                      >
                        {index + 1}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          fontSize: "0.8rem",
                        }}
                      >
                        <div style={{ fontWeight: "600" }}>
                          {value.customerName
                            ? value.customerName
                            : value.customerData.name.replace(
                                /(^\w{1})|(\s+\w{1})/g,
                                (letter) => letter.toUpperCase()
                              )}
                        </div>
                        <div
                          style={{
                            fontSize: "0.7rem",
                            textAlign: "left",
                          }}
                        >
                          {value.stopNickname || value.name}
                        </div>
                      </div>
                      <div
                        style={{
                          marginLeft: "auto",
                          marginRight: "4%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "0.7rem",
                            textAlign: "right",
                            fontWeight: "600",
                          }}
                        >
                          {value.address.split(",")[0]}
                        </div>
                        <div
                          onClick={() => props.handleViewDetails(value)}
                          style={{
                            fontSize: "0.7rem",
                            marginLeft: "auto",
                            cursor: "pointer",
                            textAlign: "left",
                            fontWeight: "600",
                          }}
                        >
                          View Details
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        )}
      </div>

      {props.hasUnactivatedChanges && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "absolute",
            left: "34%",
            top: 72,
            zIndex: 1000,
          }}
        >
          <FiAlertOctagon color="red" size={60} />
          <div style={{ marginLeft: 20, color: "red" }}>
            This route has unactivated changes!
          </div>
        </div>
      )}
    </div>
  );
};

export default SideMenu;
