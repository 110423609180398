import dayjs from "dayjs";
import { useState } from "react";
import { useEffect } from "react";
import { ArrowDownIcon } from "@heroicons/react/outline";

const StopDetailsCard = (props) => {
  const value = props.value;
  const changesData = props.changesData;
  const selectedRoute = props.selectedRoute;
  const [change, setChange] = useState(false);

  useEffect(() => {
    if (changesData) {
      setChange(changesData.find((a) => a.stopId === value.stopId));
    }
  }, [changesData, value]);

  return change ? (
    <div
      key={value.markerId}
      style={{
        border: "1px solid black",
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
        width: "200px",
        backgroundColor: "white",
        marginTop: 8,
        minHeight: "220px",
        padding: 4,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          fontSize: "0.8rem",
          paddingLeft: "4%",
        }}
      >
        <div>
          {value.name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
            letter.toUpperCase()
          )}
        </div>
        <div>{value.address.substring(0, 22)}...</div>

        <div>
          {value.recurringStopType.stopName.replace(
            /(^\w{1})|(\s+\w{1})/g,
            (letter) => letter.toUpperCase()
          )}
        </div>

        {!value.assigned && <div>Unassigned</div>}

        {value.assigned && (
          <div>
            {value.employeeName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase()
            )}
          </div>
        )}

        {value.assigned && (
          <div style={{ display: "flex" }}>
            <div style={{ marginLeft: "0%" }}>{value.day}</div>
          </div>
        )}
        {selectedRoute.active && value.assigned && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "96%",
              fontSize: "0.8rem",
              borderTop: "1px solid gray",
              marginTop: 6,
            }}
          >
            <div style={{ color: "black", marginTop: 6 }}>
              Upcoming Service Change:
            </div>

            <div>
              {change.to.employeeName.replace(
                /(^\w{1})|(\s+\w{1})/g,
                (letter) => letter.toUpperCase()
              )}
            </div>

            <div style={{ display: "flex" }}>
              <div>{change.to.day}</div>
            </div>

            <div style={{ display: "flex" }}>
              <div>{change.startOn}</div>
            </div>
            <div
              onClick={() => props.handleCancelChange(change)}
              style={{ color: "red", cursor: "pointer" }}
            >
              Cancel Change
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div
      key={value.markerId}
      style={{
        border: "1px solid black",
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
        width: "200px",
        backgroundColor: "white",
        marginTop: 8,
        minHeight: "128px",
        padding: 4,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          fontSize: "0.8rem",
          paddingLeft: "4%",
        }}
      >
        <div>
          {value.name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
            letter.toUpperCase()
          )}
        </div>
        <div>{value.address.substring(0, 22)}...</div>

        <div>
          {value.recurringStopType.stopName.replace(
            /(^\w{1})|(\s+\w{1})/g,
            (letter) => letter.toUpperCase()
          )}
        </div>

        {!value.assigned && <div>Unassigned</div>}

        {value.assigned && (
          <div>
            {value.employeeName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase()
            )}
          </div>
        )}

        {value.assigned && (
          <div style={{ display: "flex" }}>
            <div style={{ marginLeft: "0%" }}>{value.day}</div>
          </div>
        )}
        {selectedRoute.active && value.assigned && (
          <div
            onClick={() => props.handleMoveClick(value)}
            style={{ color: "blue", cursor: "pointer" }}
          >
            Change Schedule
          </div>
        )}
      </div>
    </div>
  );
};

export default StopDetailsCard;
