import React, { useEffect } from "react";
import SelectCategory from "./SelectCategory";

const Details = (props) => {
  const jobData = props.jobData;

  useEffect(() => {
    console.log(jobData);
  }, [jobData]);

  return jobData ? (
    <div
      style={{
        padding: "12px",
        overflowY: "auto",
        maxHeight: "100vh",
        paddingBottom: "100px",
      }}
    >
      <div>
        <h3 style={{ fontSize: "18px", fontWeight: 600 }}>Customer</h3>
        <div
          style={{
            marginTop: "4%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
            src="https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef"
            alt="Customer"
          />
          <div
            style={{
              fontSize: "20px",
              fontWeight: 400,
              margin: 0,
              marginLeft: 10,
            }}
          >
            {(
              jobData.customerData.customerFirstName +
              " " +
              jobData.customerData.customerLastName
            ).replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "8%" }}>
        <h3 style={{ fontSize: "18px", fontWeight: 600 }}>Job Title</h3>
        <div
          style={{
            marginTop: "4%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "20px",
              fontWeight: 400,
              margin: 0,
              width: "100%",
            }}
          >
            <input
              onChange={(change) =>
                props.handleUpdateJobName(change.target.value)
              }
              value={jobData.jobTitle}
              type="text"
              className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2"
            />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "8%" }}>
        <h3 style={{ fontSize: "18px", fontWeight: 600 }}>Job Description</h3>
        <div
          style={{
            marginTop: "4%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ fontWeight: 400, margin: 0, width: "100%" }}>
            <input
              onChange={(change) =>
                props.handleUpdateJobDescription(change.target.value)
              }
              value={jobData.jobDescription}
              type="text"
              className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2"
            />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "8%" }}>
        <h3 style={{ fontSize: "18px", fontWeight: 600 }}>Category</h3>
        <div
          style={{
            marginTop: "4%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%", fontWeight: 400, margin: 0 }}>
            <SelectCategory
              allCategories={props.allCategories}
              selectedCategory={props.selectedCategory}
              setSelectedCategory={props.setSelectedCategory}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Details;
