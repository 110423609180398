import React, { useEffect, useState } from "react";
import CheckBox from "../../GlobalComponents/CheckBox";

const UnscheduledStopCard = (props) => {
  const value = props.value;
  const [checked, setChecked] = useState(
    props.selectedUnscheduledStops.findIndex(
      (a) => a.stopId === value.stopId
    ) !== -1
  );

  const handleChange = () => {
    if (checked) {
      props.handleUnCheckedUnscheduledStop(value);
    } else {
      props.handleCheckedUnscheduledStop(value);
    }

    setChecked(!checked);
  };

  return (
    <div
      key={value.serviceLocationId}
      style={{
        border: "1px solid #D9D9D9",
        height: 72,
        display: "flex",
        alignItems: "center",
        width: "88%",
        margin: "auto",
        marginTop: 12,
        borderRadius: 5,
      }}
    >
      <div style={{ marginLeft: "4%" }}>
        <img
          className="inline-block h-10 w-10 rounded-full"
          src={
            "https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef"
          }
        />
      </div>
      <div
        style={{ display: "flex", flexDirection: "column", marginLeft: "4%" }}
      >
        <div style={{ fontSize: "0.8rem", fontWeight: "600" }}>
          {(value.customer
            ? value.customer.customerName
              ? value.customer.customerName
              : value.customer.name
            : value.customerData.name
          ).replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            fontSize: "0.74rem",
          }}
        >
          {value.stopTitle
            ? value.stopTitle.substring(0, 20)
            : value.stopNickname}
          ...
        </div>
        <div
          onClick={() => props.handleViewDetails(value)}
          style={{
            fontSize: "0.74rem",
            cursor: "pointer",
          }}
        >
          View Details &gt;
        </div>
      </div>

      <div style={{ marginRight: "4%", cursor: "pointer", marginLeft: "auto" }}>
        <CheckBox onChange={handleChange} checked={checked} />
      </div>
    </div>
  );
};

export default UnscheduledStopCard;
