import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";

export default function CompanyChemicalMetrics(props) {
  const [costs, setCosts] = useState([]);
  const [averageMonthlyCost, setAverageMonthlyCost] = useState(0);
  const db = firebase.firestore();

  const calculateMetrics = async () => {
    // Get all customers for the business
    let allCustomersQuery = await db
      .collection("Customers")
      .where("businessIds", "array-contains", props.businessId)
      .get();

    let allCustomerIds = allCustomersQuery.docs.map((doc) => doc.id);

    // Get all reports for all customers
    const allReportsPromises = allCustomerIds.map(async (customerId) => {
      const reportsRef = await db
        .collection("Customers")
        .doc(customerId)
        .collection("PoolReports")
        .where("businessId", "==", props.businessId)
        .get();
      return reportsRef.docs.map((doc) => doc.data());
    });

    // Resolve all reports for all customers
    const allReports = (await Promise.all(allReportsPromises)).flat();

    // Get last 6 months using dayjs
    const last6Months = Array.from({ length: 6 }, (_, i) => {
      return dayjs().subtract(i, "month").format("MMMM");
    }).reverse();

    // Process data for the last 6 months
    const monthsData = await Promise.all(
      last6Months.map(async (month) => {
        // Filter reports for the current month across all customers
        const monthReports = allReports.filter(
          (report) =>
            dayjs(report.reportDate.seconds * 1000).format("MMMM") === month
        );

        // Extract chemicals from the reports
        const chemicals = monthReports
          .map((report) =>
            report.reportObjects.find((a) => a.type === "chemicalsAdded")
          )
          .filter(Boolean); // Filter out undefined values

        const chemicalObjects = chemicals.flat();

        // Fetch chemical data for all chemicals in parallel
        const chemicalCosts = await Promise.all(
          chemicalObjects.map(async (chemical) => {
            const chemsPromises = chemical.value.map(async (chem) => {
              const chemicalId = chem.chemicalData.chemicalId;

              const chemicalRef = await db
                .collection("Businesses")
                .doc(props.businessId) // Changed to businessId from props.serviceLocation.businessId
                .collection("Chemicals")
                .doc(chemicalId)
                .get();

              const chemicalData = chemicalRef.data();

              return {
                chemicalData: chemicalData,
                reportChemical: chem,
              };
            });

            // Resolve all chemical promises for this report
            return Promise.all(chemsPromises);
          })
        );

        // Flatten the result since chemicalCosts is an array of arrays
        const flatChemicalCosts = chemicalCosts.flat();

        // Combine all chemical data into a single `chemicals` array for the month
        const chemicalsArray = flatChemicalCosts.map((chemical) => ({
          chemicalName: chemical.chemicalData.chemicalName,
          value:
            parseInt(
              chemical.chemicalData.chemicalPricePerDose.replace("$", "")
            ) * chemical.reportChemical.value,
        }));

        const combinedChemicals = chemicalsArray.reduce((acc, chemical) => {
          if (!acc[chemical.chemicalName]) {
            acc[chemical.chemicalName] = 0;
          }
          acc[chemical.chemicalName] += chemical.value;
          return acc;
        }, {});

        // Convert the combined object back to an array of { chemicalName, value }
        const finalChemicalsArray = Object.entries(combinedChemicals).map(
          ([chemicalName, value]) => ({
            chemicalName,
            value,
          })
        );

        // Return data for this month with all chemicals grouped in one array
        return {
          name: month,
          chemicals: finalChemicalsArray,
        };
      })
    );

    // Calculate the average monthly cost
    const totalCosts = monthsData.reduce((acc, month) => {
      const monthTotal = month.chemicals.reduce(
        (acc, chemical) => acc + chemical.value,
        0
      );
      return acc + monthTotal;
    }, 0);

    setAverageMonthlyCost(totalCosts);

    setCosts(monthsData);
  };

  useEffect(() => {
    calculateMetrics();
  }, []);

  // Format data for the chart
  const formattedData = costs.map((month) => {
    const result = { name: month.name };
    month.chemicals.forEach((chemical) => {
      result[chemical.chemicalName] = chemical.value;
    });
    return result;
  });

  // Dynamically get chemical names from the first entry with chemicals (if any)
  const chemicalNames =
    costs
      .find((month) => month.chemicals.length > 0)
      ?.chemicals.map((chemical) => chemical.chemicalName) || [];

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div className="px-4 sm:px-6 lg:px-0">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg border-gray-200 border-2 h-[400px]">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Chemical Costs
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6 flex-shrink cursor-pointer"
                    >
                      Average Monthly Cost: ${averageMonthlyCost.toFixed(2)}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white pt-8">
                  <tr>
                    <td className="px-4 py-4">
                      {/* Recharts Bar chart rendered here */}
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={formattedData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis tickFormatter={(value) => `$${value}`} />
                          <Tooltip formatter={(value) => `$${value}`} />
                          <Legend />
                          {/* Render a Bar for each chemical */}
                          {chemicalNames.map((chemicalName, index) => (
                            <Bar
                              key={chemicalName}
                              dataKey={chemicalName}
                              fill={getRandomColor()} // Generate a random color
                            />
                          ))}
                        </BarChart>
                      </ResponsiveContainer>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
