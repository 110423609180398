import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import GoogleMapReact from "google-map-react";
import SideMenu from "./SideMenu";
import Geocoder from "react-native-geocoding";
import { generateBusinessId } from "../../Tools/generateBusinessId";
import dayjs from "dayjs";
import RecheduleRecurringStop from "../../GlobalComponents/RescheduleRecurringStop";
import RescheduleOneTimeStop from "../../GlobalComponents/RescheduleOneTimeStop";
import ViewDetailsRec from "./ViewDetailsRec";
import ViewDetailsOneTime from "./ViewDetailsOneTime";
import ScheduleOneTimeStop from "../../GlobalComponents/ScheduleOneTimeStop";
import StopDetailsCard from "./StopDetailsCard";
import { MdSync } from "react-icons/md";
import { toast } from "react-toastify";

Geocoder.init(process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN);

const Popover = ({
  stopCount,
  handleSelectSubMarker,
  multiStopMarkerData,
  selectedSubMarker,
  markers,
  refresh,
}) => {
  const [assignedMarkers, setAssignedMarkers] = useState([]);
  const hiddenMarkers = [];

  useEffect(() => {
    let matchesAssignedMarkers = [];

    for (let index = 0; index < markers.length; index++) {
      const marker = markers[index];

      // If the marker is not hidden and matches the serviceLocationId and the day is not "none"
      if (
        marker.serviceLocationId ===
        multiStopMarkerData.value[0].serviceLocationId
      ) {
        matchesAssignedMarkers.push(marker);
      }
    }

    // Log and set the new array

    setAssignedMarkers(matchesAssignedMarkers);
  }, [refresh, markers, multiStopMarkerData, stopCount]);

  return (
    <div
      style={{
        position: "absolute",
        bottom: "10px",
        left: "50%",
        width: 180,
        minHeight: 60,
        transform: "translateX(-50%)",
        backgroundColor: "white",
        padding: "15px",
        borderRadius: "4px",
        zIndex: 1000,
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {assignedMarkers.map((value, index) => (
        <div
          onClick={(e) => {
            e.stopPropagation();

            handleSelectSubMarker(index, value);
          }}
          key={index}
          style={{
            width: 40,
            height: 40,
            backgroundColor: "grey",
            borderRadius: "50%",
            display: "flex",
            border: "4px solid",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            margin: 4,
            borderColor:
              selectedSubMarker === index
                ? "red"
                : value.day === "Monday"
                ? "#976DD0"
                : value.day === "Tuesday"
                ? "#FFBA5C"
                : value.day === "Wednesday"
                ? "#77D353"
                : value.day === "Thursday"
                ? "#F95F62"
                : value.day === "Friday"
                ? "#235789"
                : value.day === "Saturday"
                ? "#ED7D3A"
                : value.day === "Sunday"
                ? "#4F46E5"
                : "black",
          }}
        >
          {value.day !== "none" && (
            <div>
              {value.employeeName
                ? value.employeeName
                    .match(/(\b\S)?/g)
                    .join("")
                    .match(/(^\S|\S$)?/g)
                    .join("")
                    .toUpperCase()
                : "Unavailable"}
            </div>
          )}
        </div>
      ))}
      <div
        style={{
          position: "absolute",
          bottom: "-10px",
          left: "50%",
          transform: "translateX(-50%)",
          width: 0,
          height: 0,
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "10px solid white",
          zIndex: 2,
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom: "-8px",
          left: "50%",
          transform: "translateX(-50%)",
          width: 0,
          height: 0,
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "10px solid rgba(0, 0, 0, 0.1)",
          zIndex: 1,
          filter: "blur(4px)",
        }}
      />
    </div>
  );
};

const MarkerDots = ({ value, markers, refresh }) => {
  const [assignedMarkers, setAssignedMarkers] = useState([]);
  useEffect(() => {
    let matchesAssignedMarkers = [];

    for (let index = 0; index < markers.length; index++) {
      const marker = markers[index];

      if (marker.serviceLocationId === value.serviceLocationId) {
        matchesAssignedMarkers.push(marker);
      }
    }

    setAssignedMarkers(matchesAssignedMarkers);
  }, [markers, value]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        maxWidth: 30,
      }}
    >
      {assignedMarkers.map((value, index) => (
        <span
          key={index}
          style={{
            width: "6px",
            height: "6px",
            backgroundColor:
              value.day === "Monday"
                ? "#4F46E5"
                : value.day === "Tuesday"
                ? "#FFBA5C"
                : value.day === "Wednesday"
                ? "#77D353"
                : value.day === "Thursday"
                ? "#F95F62"
                : value.day === "Friday"
                ? "#235789"
                : value.day === "Saturday"
                ? "#ED7D3A"
                : value.day === "Sunday"
                ? "#4F46E5"
                : "grey",
            borderRadius: "50%",
            display: "inline-block",
            margin: 1,
          }}
        ></span>
      ))}
    </div>
  );
};

const ActiveRoute = (props) => {
  const db = firebase.firestore();
  const selectedBusiness = props.businessId;
  const [selectedRoute, setSelectedRoute] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [serviceLocationsInRoute, setServiceLocationsInRoute] = useState([]);
  const markers = useRef();
  const [hoverDisplay, setHoverDisplay] = useState(false);
  const [hoverDisplayData, setHoverDisplayData] = useState({});
  const [routeStartDate, setRouteStartDate] = useState(new Date());
  const [mapsObject, setMapsObject] = useState();
  const [mapObject, setMapObject] = useState();
  const [mapCenterCords, setMapCenterCords] = useState();
  const serviceLocationsOrder = useRef();
  const [hasUnactivatedChanges, setHasUnactivatedChanges] = useState(false);
  const [unscheduledMarkers, setUnscheduledMarkers] = useState([]);
  const [rescheduledMarkers, setRescheduledMarkers] = useState([]);
  const [selectedUnscheduledStop, setSelectedUnscheduledStop] = useState({});
  const [reloadUnscheduledStops, setReloadUnscheduledStops] = useState(false);
  const [mapWidth, setMapWidth] = useState("0px");
  const [rescheduleDisplay, setRescheduleDisplay] = useState(false);
  const [oneTimeRescheduleDisplay, setOneTimeRescheduleDisplay] =
    useState(false);
  const [rescheduleData, setRescheduleData] = useState();
  const [rescheduledStops, setRescheduledStops] = useState([]);
  const [viewDetailsOneTimeDisplay, setViewDetailsOneTimeDisplay] =
    useState(false);
  const [viewDetailsRecDisplay, setViewDetailsRecDisplay] = useState(false);
  const [viewDetailsData, setViewDetailsData] = useState();
  const [selectedUnscheduledStops, setSelectedUnscheduledStops] = useState([]);
  const [scheduleOneTimeStopDisplay, setScheduleOneTimeStopDisplay] =
    useState(false);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState();
  const routeOrder = useRef();
  const [dayViewDisplay, setDayViewDisplay] = useState(false);
  const [dayViewData, setDayViewData] = useState({});
  const [dayData, setDayData] = useState([]);
  const orderListener = useRef();
  const [allEmployees, setAllEmployees] = useState([]);
  const [lastReloadValue, setLastReloadValue] = useState(null);
  const initialLoad = useRef();
  const [customRouteOrder, setCustomerRouteOrder] = useState([]);
  const [markersDataForProp, setMarkersDataForProp] = useState([]);
  const [forceEmployeeCardsReload, setForceEmployeeCardsReload] =
    useState(false);
  const [selectedSubMarker, setSelectedSubMarker] = useState();
  const [multiStopMarkerDisplay, setMultiStopMarkerDisplay] = useState(false);
  const [multiStopMarkerData, setMultiStopMarkerData] = useState({});
  const [defaultZoom, setDefaultZoom] = useState();
  const [routeHasProcessingChanges, setRouteHasProcessingChanges] =
    useState(false);
  const lastReloadValueRef = useRef();
  const [hiddenEmployees, setHiddenEmployees] = useState([]);

  useEffect(() => {
    routeOrder.current = [];

    setMapWidth(document.getElementsByClassName("py-6")[0].offsetWidth + "px");
    markers.current = [];
    setMarkersDataForProp([]);
    let listener = null;

    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        // setup a listener that specifically waits for the reloaad field to change
        if (!querySnapshot.empty) {
          const routeData = querySnapshot.docs[0].data();
          lastReloadValueRef.current = routeData.reload;
          setLastReloadValue(routeData.reload);

          setSelectedRoute(routeData);
        }
      });

    listener = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .onSnapshot((documentSnapshot) => {
        const bData = documentSnapshot.data();
        setRouteHasProcessingChanges(bData.routeChangesPending ? true : false);
      });

    db.collection("Businesses")
      .doc(selectedBusiness)
      .get()
      .then((documentSnapshot) => {
        const bData = documentSnapshot.data();
        Geocoder.from(bData.serviceAreaZipcode)
          .then((json) => {
            var location = json.results[0].geometry.location;
            setMapCenterCords(location);
          })
          .catch((error) => console.warn(error));
      });

    return () => {
      if (listener) listener();
    };
  }, []);

  const handleSelectSubMarker = (index, value) => {
    if (index === selectedSubMarker) {
      setSelectedSubMarker();
      setHoverDisplayData({});
      setHoverDisplay(false);
      return;
    }
    setSelectedSubMarker(index);

    setHoverDisplayData({ value: value });
    setHoverDisplay(true);
  };

  useEffect(() => {
    const storedZoomValue = localStorage.getItem("mapZoom");
    if (storedZoomValue !== null) {
      const zoom = parseInt(storedZoomValue, 10);
      // Use the zoom value as needed
      setDefaultZoom(zoom);
    } else {
      setDefaultZoom(11);
    }
  }, []);

  const handleZoomValue = (zoom) => {
    // Convert the zoom value to a string and store it in local storage
    localStorage.setItem("mapZoom", zoom.toString());
  };

  const checkProcessingChanges = async () => {
    const documentSnapshot = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .get();
    const bData = documentSnapshot.data();
    return bData.routeChangesPending ? true : false;
  };

  useEffect(() => {
    initialLoad.current = true;
    const unsubscribe = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .where("active", "==", true)
      .onSnapshot(async (querySnapshot) => {
        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0].data();
          const newReloadValue = docData.reload;

          const hasProcessingChanges = await checkProcessingChanges();

          if (
            (!hasProcessingChanges &&
              newReloadValue !== lastReloadValueRef.current) ||
            (initialLoad.current && !hasProcessingChanges)
          ) {
            initialLoad.current = false;
            setSelectedRoute(docData);

            setLastReloadValue(newReloadValue);
            lastReloadValueRef.current = newReloadValue;
            const route = docData;

            serviceLocationsOrder.current = [];
            setHoverDisplayData({});
            setHoverDisplay(false);
            setSelectedMarkerIndex();

            setUnscheduledMarkers([]);
            setRescheduledMarkers([]);
            markers.current = [];
            setMarkersDataForProp([]);

            setTimeout(() => {
              db.collection("Businesses")
                .doc(selectedBusiness)
                .collection("Routes")
                .doc(route.routeId)
                .collection("Employees")
                .get()
                .then((snapshot) => {
                  let arrOfServiceLocations = [];

                  let arrOfEmployees = [];

                  snapshot.docs.forEach((value) => {
                    const data = value.data();
                    arrOfEmployees.push(data);
                  });
                  setAllEmployees(arrOfEmployees);

                  let promises = snapshot.docs.map(async (doc) => {
                    const docData = doc.data();

                    return db
                      .collection("Businesses")
                      .doc(selectedBusiness)
                      .collection("Workers")
                      .doc(docData.uid)
                      .collection("Route")
                      .doc(dayjs(routeStartDate).format("dddd"))
                      .collection("Stops")
                      .get()
                      .then(async (snap) => {
                        let arrOfHiddenStops = [];

                        return db
                          .collection("Businesses")
                          .doc(selectedBusiness)
                          .collection("Workers")
                          .doc(docData.uid)
                          .collection("Route")
                          .doc(dayjs(routeStartDate).format("dddd"))
                          .collection("MovedStops")
                          .get()
                          .then(async (snapshotQuery) => {
                            if (snapshotQuery && snapshotQuery.docs) {
                              snapshotQuery.docs.forEach((value) => {
                                if (
                                  dayjs(routeStartDate).format("MM/DD/YYYY") ===
                                  value.data().originalDate
                                ) {
                                  arrOfHiddenStops.push(value.data().stopId);
                                }
                              });
                            }

                            if (!snap.empty) {
                              snap.docs.forEach((value, index) => {
                                if (
                                  !arrOfHiddenStops.includes(
                                    value.data().tempStopId
                                      ? value.data().tempStopId
                                      : value.data().stopId
                                  )
                                ) {
                                  arrOfServiceLocations.push(value.data());
                                }
                              });
                              return db
                                .collection("Businesses")
                                .doc(selectedBusiness)
                                .collection("Workers")
                                .doc(docData.uid)
                                .collection("Route")
                                .doc("JobStops")
                                .get()
                                .then((documentSnapshot) => {
                                  if (documentSnapshot.exists) {
                                    const jobStopsData =
                                      documentSnapshot.data();
                                    if (jobStopsData.route) {
                                      jobStopsData.route.forEach(
                                        (value, index) => {
                                          if (
                                            !arrOfHiddenStops.includes(
                                              value.tempStopId
                                                ? value.tempStopId
                                                : value.stopId
                                            )
                                          ) {
                                            arrOfServiceLocations.push(value);
                                          }
                                        }
                                      );
                                    }
                                  }
                                });
                            } else {
                              return db
                                .collection("Businesses")
                                .doc(selectedBusiness)
                                .collection("Workers")
                                .doc(docData.uid)
                                .collection("Route")
                                .doc("JobStops")
                                .get()
                                .then((documentSnapshot) => {
                                  if (documentSnapshot.exists) {
                                    const jobStopsData =
                                      documentSnapshot.data();
                                    jobStopsData.route.forEach(
                                      (value, index) => {
                                        if (
                                          !arrOfHiddenStops.includes(
                                            value.tempStopId
                                              ? value.tempStopId
                                              : value.stopId
                                          )
                                        ) {
                                          arrOfServiceLocations.push(value);
                                        }
                                      }
                                    );
                                  }
                                });
                            }
                          });
                      });
                  });

                  Promise.all(promises)
                    .then(async () => {
                      const unscheduled = await handleGetUnscheduled(
                        arrOfEmployees
                      );
                      await handleGetRescheduled(arrOfEmployees);
                      setServiceLocationsInRoute(arrOfServiceLocations);

                      //setForceEmployeeCardsReload(!forceEmployeeCardsReload);

                      getAllPoolAddresses(arrOfServiceLocations, unscheduled);
                    })
                    .catch((err) => console.error(err)); // Handle errors if any
                });
            }, 2000);
          }
        }
      });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleGetRescheduled = async (arrOfEmployees) => {
    const businessRef = db.collection("Businesses").doc(selectedBusiness);

    let currentRescheduledMarkers = [];

    const employeePromises = arrOfEmployees.map(async (employee) => {
      const workerRef = businessRef.collection("Workers").doc(employee.uid);
      const routesSnapshot = await workerRef.collection("Route").get();
      const routePromises = routesSnapshot.docs.map(async (routeDoc) => {
        const dayName = routeDoc.id;
        const stopsSnapshot = await workerRef
          .collection("Route")
          .doc(dayName)
          .collection("MovedStops")
          .get();

        stopsSnapshot.docs.forEach(async (stopDoc) => {
          let stop = stopDoc.data();
          // check if the stop.date is older than 7 days if it is skip
          if (dayjs().diff(dayjs(stop.originalDate), "days") > 7) {
            return;
          }
          stop.employeeUid = employee.uid;
          stop.businessId = selectedBusiness;
          const customersData = await db
            .collection("Businesses")
            .doc(stop.businessId)
            .collection("Workers")
            .doc(stop.employeeUid)
            .collection("Route")
            .doc(dayjs(stop.originalDate).format("dddd"))
            .collection("Stops")
            .doc(stop.stopId)
            .get();

          if (customersData.exists) {
            const stopData = customersData.data();
            stop.customerName = stopData.customerData.name;
            currentRescheduledMarkers.push(stop);
          }
        });
      });
      await Promise.all(routePromises);
    });

    await Promise.all(employeePromises);

    const jobsSnapshot = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Jobs")
      .get();

    console.log(currentRescheduledMarkers);
    setRescheduledMarkers(currentRescheduledMarkers);

    // return the array
  };

  const handleGetUnscheduled = async (arrOfEmployees) => {
    const businessRef = db.collection("Businesses").doc(selectedBusiness);

    let currentUnscheduledMarkers = new Map();

    const employeePromises = arrOfEmployees.map(async (employee) => {
      const workerRef = businessRef.collection("Workers").doc(employee.uid);
      const routesSnapshot = await workerRef.collection("Route").get();
      const routePromises = routesSnapshot.docs.map(async (routeDoc) => {
        const dayName = routeDoc.id;
        const stopsSnapshot = await workerRef
          .collection("Route")
          .doc(dayName)
          .collection("Stops")
          .get();
        stopsSnapshot.docs.forEach((stopDoc) => {
          const stop = stopDoc.data();
          if (
            stop.unscheduledDates &&
            stop.unscheduledDates[0] &&
            stop.stopId &&
            !currentUnscheduledMarkers.has(
              stop.tempStopId ? stop.tempStopId : stop.stopId
            )
          ) {
            if (
              stop.rescheduledDates &&
              !stop.rescheduledDates.some((a) =>
                stop.unscheduledDates.includes(a)
              )
            ) {
              if (stop.customerData) {
                currentUnscheduledMarkers.set(stop.stopId, stop);
              }
            }
          }
        });
      });
      await Promise.all(routePromises);
    });

    await Promise.all(employeePromises);

    const jobsSnapshot = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Jobs")
      .get();

    jobsSnapshot.docs.forEach((doc) => {
      const data = doc.data();
      if (data.unscheduledStops && data.status !== "complete") {
        data.unscheduledStops.forEach((stop) => {
          if (
            !currentUnscheduledMarkers.has(
              stop.tempStopId ? stop.tempStopId : stop.stopId
            )
          ) {
            console.log(stop);
            currentUnscheduledMarkers.set(
              stop.tempStopId ? stop.tempStopId : stop.stopId,
              stop
            );
          }
        });
      }
    });
    const unscheduledMarkers = Array.from(currentUnscheduledMarkers.values());

    console.log(unscheduledMarkers);

    setUnscheduledMarkers(unscheduledMarkers);

    // return the array
    return unscheduledMarkers;
  };

  const handleReloadEntireRoute = (unscheduledStop) => {
    serviceLocationsOrder.current = [];
    setHoverDisplayData({});
    setHoverDisplay(false);
    setSelectedMarkerIndex();

    setUnscheduledMarkers([]);
    setRescheduledMarkers([]);
    markers.current = [];
    setMarkersDataForProp([]);

    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .collection("Employees")
      .get()
      .then((snapshot) => {
        let arrOfServiceLocations = [];

        let arrOfEmployees = [];

        snapshot.docs.forEach((value) => {
          const data = value.data();
          arrOfEmployees.push(data);
        });
        setAllEmployees(arrOfEmployees);

        let promises = snapshot.docs.map(async (doc) => {
          const docData = doc.data();
          return db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Workers")
            .doc(docData.uid)
            .collection("Route")
            .doc(dayjs(routeStartDate).format("dddd"))
            .collection("Stops")
            .get()
            .then(async (snap) => {
              let arrOfHiddenStops = [];

              return db
                .collection("Businesses")
                .doc(selectedBusiness)
                .collection("Workers")
                .doc(docData.uid)
                .collection("Route")
                .doc(dayjs(routeStartDate).format("dddd"))
                .collection("MovedStops")
                .get()
                .then(async (snapshotQuery) => {
                  if (snapshotQuery && snapshotQuery.docs) {
                    snapshotQuery.docs.forEach((value) => {
                      if (
                        dayjs(routeStartDate).format("MM/DD/YYYY") ===
                        value.data().originalDate
                      ) {
                        arrOfHiddenStops.push(value.data().stopId);
                      }
                    });
                  }

                  if (!snap.empty) {
                    snap.docs.forEach((value, index) => {
                      if (
                        !arrOfHiddenStops.includes(
                          value.data().tempStopId
                            ? value.data().tempStopId
                            : value.data().stopId
                        )
                      ) {
                        arrOfServiceLocations.push(value.data());
                      }
                    });
                    return db
                      .collection("Businesses")
                      .doc(selectedBusiness)
                      .collection("Workers")
                      .doc(docData.uid)
                      .collection("Route")
                      .doc("JobStops")
                      .get()
                      .then((documentSnapshot) => {
                        if (documentSnapshot.exists) {
                          const jobStopsData = documentSnapshot.data();
                          if (jobStopsData.route) {
                            jobStopsData.route.forEach((value, index) => {
                              if (
                                !arrOfHiddenStops.includes(
                                  value.tempStopId
                                    ? value.tempStopId
                                    : value.stopId
                                )
                              ) {
                                arrOfServiceLocations.push(value);
                              }
                            });
                          }
                        }
                      });
                  } else {
                    return db
                      .collection("Businesses")
                      .doc(selectedBusiness)
                      .collection("Workers")
                      .doc(docData.uid)
                      .collection("Route")
                      .doc("JobStops")
                      .get()
                      .then((documentSnapshot) => {
                        if (documentSnapshot.exists) {
                          const jobStopsData = documentSnapshot.data();
                          jobStopsData.route.forEach((value, index) => {
                            if (
                              !arrOfHiddenStops.includes(
                                value.tempStopId
                                  ? value.tempStopId
                                  : value.stopId
                              )
                            ) {
                              arrOfServiceLocations.push(value);
                            }
                          });
                        }
                      });
                  }
                });
            });
        });

        Promise.all(promises)
          .then(async () => {
            const unscheduled = await handleGetUnscheduled(arrOfEmployees);
            await handleGetRescheduled(arrOfEmployees);
            setServiceLocationsInRoute(arrOfServiceLocations);
            setForceEmployeeCardsReload(!forceEmployeeCardsReload);
            getAllPoolAddresses(arrOfServiceLocations, unscheduled);
          })
          .catch((err) => console.error(err)); // Handle errors if any
      });
  };

  useEffect(() => {
    serviceLocationsOrder.current = [];
    setHoverDisplayData({});
    setHoverDisplay(false);
    setSelectedMarkerIndex();
    markers.current = [];
    setMarkersDataForProp([]);

    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Routes")
      .doc(selectedRoute.routeId)
      .collection("Employees")
      .get()
      .then((snapshot) => {
        let arrOfServiceLocations = [];

        let arrOfEmployees = [];

        // Create an array of promises
        let promises = snapshot.docs.map(async (doc) => {
          const docData = doc.data();
          arrOfEmployees.push(docData);
          return db
            .collection("Businesses")
            .doc(selectedBusiness)
            .collection("Workers")
            .doc(docData.uid)
            .collection("Route")
            .doc(dayjs(routeStartDate).format("dddd"))
            .collection("Stops")
            .get()
            .then(async (snap) => {
              let arrOfHiddenStops = [];
              return db
                .collection("Businesses")
                .doc(selectedBusiness)
                .collection("Workers")
                .doc(docData.uid)
                .collection("Route")
                .doc(dayjs(routeStartDate).format("dddd"))
                .collection("MovedStops")
                .get()
                .then(async (snapshotQuery) => {
                  if (snapshotQuery && snapshotQuery.docs) {
                    snapshotQuery.docs.forEach((value) => {
                      if (
                        dayjs(routeStartDate).format("MM/DD/YYYY") ===
                        value.data().originalDate
                      ) {
                        arrOfHiddenStops.push(value.data().stopId);
                      }
                    });
                  }

                  if (!snap.empty) {
                    snap.docs.forEach((value, index) => {
                      if (
                        !arrOfHiddenStops.includes(
                          value.data().tempStopId
                            ? value.data().tempStopId
                            : value.data().stopId
                        )
                      ) {
                        arrOfServiceLocations.push(value.data());
                      }
                    });
                    return db
                      .collection("Businesses")
                      .doc(selectedBusiness)
                      .collection("Workers")
                      .doc(docData.uid)
                      .collection("Route")
                      .doc("JobStops")
                      .get()
                      .then((documentSnapshot) => {
                        if (documentSnapshot.exists) {
                          const jobStopsData = documentSnapshot.data();
                          jobStopsData.route.forEach((value, index) => {
                            if (
                              !arrOfHiddenStops.includes(
                                value.tempStopId
                                  ? value.tempStopId
                                  : value.stopId
                              )
                            ) {
                              arrOfServiceLocations.push(value);
                            }
                          });
                        }
                      });
                  } else {
                    return db
                      .collection("Businesses")
                      .doc(selectedBusiness)
                      .collection("Workers")
                      .doc(docData.uid)
                      .collection("Route")
                      .doc("JobStops")
                      .get()
                      .then((documentSnapshot) => {
                        if (documentSnapshot.exists) {
                          const jobStopsData = documentSnapshot.data();
                          jobStopsData.route.forEach((value, index) => {
                            if (
                              !arrOfHiddenStops.includes(
                                value.tempStopId
                                  ? value.tempStopId
                                  : value.stopId
                              )
                            ) {
                              arrOfServiceLocations.push(value);
                            }
                          });
                        }
                      });
                  }
                });
            });
        });

        // Only proceed when all promises have resolved
        Promise.all(promises)
          .then(async () => {
            setAllEmployees(arrOfEmployees);
            const unscheduled = await handleGetUnscheduled(arrOfEmployees);
            await handleGetRescheduled(arrOfEmployees);
            setServiceLocationsInRoute(arrOfServiceLocations);
            getAllPoolAddresses(arrOfServiceLocations, unscheduled);
          })
          .catch((err) => console.error(err)); // Handle errors if any
      });
  }, [routeStartDate]);

  function getAdjustedLongitude(
    markers,
    props,
    routeStartDate,
    selectedUnscheduledStops
  ) {
    // Helper to determine if a marker is part of a multistop
    const isMultistop = (marker) =>
      markers
        .filter(
          (a) => a.serviceLocationId === marker.serviceLocationId && !a.hidden
        )
        .filter((a) =>
          props.searchTerm.length === 0
            ? true
            : a.address.toLowerCase().includes(props.searchTerm.toLowerCase())
        )
        .filter((a) =>
          !a.assigned
            ? selectedUnscheduledStops.some(
                (b) =>
                  (b.tempStopId ? b.tempStopId : b.stopId) ===
                  (a.tempStopId ? a.tempStopId : a.stopId)
              )
            : true
        )
        .filter((a) =>
          a.unscheduledDates
            ? !a.unscheduledDates.includes(
                dayjs(routeStartDate).format("MM/DD/YYYY")
              )
            : true
        )
        .filter((a) =>
          a.tempStopId
            ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
            : true
        ).length > 1;

    // Create a map to group markers by their latitude and longitude
    const groupedMarkers = markers.reduce((acc, marker) => {
      const key = `${marker.location.lat},${marker.location.lng}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(marker);
      return acc;
    }, {});

    // Adjust the longitude for markers in the same group or handle multistops
    const adjustedMarkers = [];
    Object.values(groupedMarkers).forEach((group) => {
      if (group.some(isMultistop)) {
        // If any marker in the group is a multistop, add only the first marker as-is
        adjustedMarkers.push(group[0]);
      } else {
        // Adjust longitude for non-multistop markers
        group.forEach((marker, index) => {
          const adjustedMarker = {
            ...marker,
            location: {
              ...marker.location,
              lng: marker.location.lng + 0.004 * index, // Adjust for spacing
            },
          };
          adjustedMarkers.push(adjustedMarker);
        });
      }
    });

    return adjustedMarkers;
  }

  const getAllPoolAddresses = (serviceLocations, unscheduled) => {
    let addArr = [];
    if (routeOrder.current.length === 0) {
      let orderArr = [];
      for (let index = 0; index < serviceLocations.length; index++) {
        const serviceLocation = serviceLocations[index];
        if (serviceLocation.dayOfTheWeek) {
          if (
            serviceLocation.frequency !== "once"
              ? serviceLocation.dayOfTheWeek ===
                dayjs(routeStartDate).format("dddd")
              : serviceLocation.stopDate ===
                dayjs(routeStartDate).format("MM/DD/YYYY")
          ) {
            orderArr.push(
              serviceLocation.tempStopId
                ? serviceLocation.tempStopId
                : serviceLocation.stopId
            );
          }
        } else if (
          serviceLocation.frequency === "once" &&
          serviceLocation.stopObject &&
          serviceLocation.stopObject.stopDate ===
            dayjs(routeStartDate).format("MM/DD/YYYY")
        ) {
          orderArr.push(serviceLocation.stopId);
        }
      }
      routeOrder.current = orderArr;
    }

    const sort = (a, b) => {
      return (
        routeOrder.current.indexOf(a.tempStopId ? a.tempStopId : a.stopId) -
        routeOrder.current.indexOf(b.tempStopId ? b.tempStopId : b.stopId)
      );
    };
    let sortedServiceLocations = serviceLocations;
    sortedServiceLocations.sort(sort);

    sortedServiceLocations.forEach((value, index) => {
      let location = value.location || value.coordinates;

      const serviceLocationData = value;

      const markerId = generateBusinessId();

      if (value.dayOfTheWeek) {
        if (
          value.frequency !== "once"
            ? value.dayOfTheWeek === dayjs(routeStartDate).format("dddd")
            : value.stopDate === dayjs(routeStartDate).format("MM/DD/YYYY")
        ) {
          if (value.tempStopId) {
            console.log(value);
            addArr.push({
              location: location,
              name: serviceLocationData.name,
              address: serviceLocationData.address,
              serviceLocationId: serviceLocationData.serviceLocationId,
              routeId: selectedRoute.routeId,
              customerId: serviceLocationData.customerId || value.customerId,
              assigned: true,
              day: value.dayOfTheWeek ? value.dayOfTheWeek : "none",
              dayOfTheWeek: value.dayOfTheWeek ? value.dayOfTheWeek : "none",
              originalDate: value.originalDate,
              originalEmployee: value.originalEmployee,
              employeePhoto: value.contractor
                ? value.contractor.profilePhoto
                : "none",
              employeeUid: value.contractor ? value.contractor.id : "none",
              employeeName: value.contractor.name,
              recurringStopType: serviceLocationData.recurringStopType,
              markerId: markerId,
              type: "recStop",
              stopId: value.stopId,
              customerName: value.customerData.name,
              customerData: value.customerData,
              stopObject: value.stopObject,
              completedDates: value.completedDates,
              stopWorkerDescription: value.stopWorkerDescription,
              stopCustomerDescription: value.stopCustomerDescription,
              stopTemplateId: value.stopTemplateId,
              stopNickname: value.stopNickname,
              contractor: value.contractor,
              stopCount: 3,
              unscheduledDates: value.unscheduledDates,
              rescheduledDates: value.rescheduledDates,
              tempStopId: value.tempStopId,
              date: value.date,
              hidden: hiddenEmployees.includes(value.contractor.id),
            });
          } else {
            addArr.push({
              location: location,
              name: serviceLocationData.name,
              address: serviceLocationData.address,
              serviceLocationId: serviceLocationData.serviceLocationId,
              routeId: selectedRoute.routeId,
              customerId:
                serviceLocationData.customerId || value.customerData.customerId,
              assigned: true,
              day: value.dayOfTheWeek ? value.dayOfTheWeek : "none",
              dayOfTheWeek: value.dayOfTheWeek ? value.dayOfTheWeek : "none",
              employeePhoto: value.contractor
                ? value.contractor.profilePhoto
                : "none",
              employeeUid: value.contractor ? value.contractor.id : "none",
              employeeName: value.contractor.name,
              recurringStopType: value.type,
              markerId: markerId,
              type: "recStop",
              stopId: value.stopId,
              customerName: value.customerData.name,
              customerData: value.customerData,
              stopObject: value.stopObject,
              completedDates: value.completedDates,
              stopWorkerDescription: value.stopWorkerDescription,
              stopCustomerDescription: value.stopCustomerDescription,
              stopNickname: value.stopNickname,
              stopTemplateId: value.stopTemplateId,
              stopCount: 3,
              contractor: value.contractor,
              unscheduledDates: value.unscheduledDates,
              rescheduledDates: value.rescheduledDates,
              hidden: hiddenEmployees.includes(value.contractor.id),
            });
          }
        }
      } else if (
        value.frequency === "once" &&
        value.stopObject &&
        value.stopObject.stopDate === dayjs(routeStartDate).format("MM/DD/YYYY")
      ) {
        if (value.tempStopId) {
          addArr.push({
            location: location,
            name: value.name,
            address: value.address,
            serviceLocationId: value.serviceLocationId,
            routeId: selectedRoute.routeId,
            customerId: value.customerId,
            assigned: true,
            originalDate: value.originalDate,
            originalEmployee: value.originalEmployee,
            day: value.stopObject.dayOfTheWeek
              ? value.stopObject.dayOfTheWeek
              : "none",
            dayOfTheWeek: value.stopObject.dayOfTheWeek
              ? value.stopObject.dayOfTheWeek
              : "none",
            employeePhoto:
              value.contractor.photo || value.contractor.profilePhoto,
            employeeUid: value.contractor.uid
              ? value.contractor.uid
              : value.contractor.id,
            employeeName: value.contractor.name,
            recurringStopType: value.recurringStopType,
            markerId: markerId,
            type: "stop",
            stopId: value.stopId,
            stopCount: 1,
            customerName: value.customerData.name,
            stopObject: value.stopObject,
            stopTemplateId: value.stopTemplateId,
            tempStopId: value.tempStopId,
            date: value.date,
            hidden: hiddenEmployees.includes(value.contractor.id),
          });
        } else {
          addArr.push({
            location: location,
            name: value.name,
            address: value.address,
            serviceLocationId: value.serviceLocationId,
            routeId: selectedRoute.routeId,
            customerId: value.customerId,
            assigned: true,
            day: value.stopObject.dayOfTheWeek
              ? value.stopObject.dayOfTheWeek
              : "none",
            dayOfTheWeek: value.stopObject.dayOfTheWeek
              ? value.stopObject.dayOfTheWeek
              : "none",
            employeePhoto:
              value.contractor.photo || value.contractor.profilePhoto,
            employeeUid: value.contractor.uid
              ? value.contractor.uid
              : value.contractor.id,
            employeeName: value.contractor.name,
            recurringStopType: value.recurringStopType,
            stopTemplateId: value.stopTemplateId,
            markerId: markerId,
            type: "stop",
            stopCount: 1,
            stopId: value.stopId,
            customerName: value.customerData.name,
            stopObject: value.stopObject,
            hidden: hiddenEmployees.includes(value.contractor.id),
          });
        }
      }
    });

    for (let index = 0; index < unscheduled.length; index++) {
      const unscheduledMarker = unscheduled[index];
      console.log(unscheduledMarker);
      // this is where I can push customer markers
      const markerId = generateBusinessId();
      addArr.push({
        location:
          unscheduledMarker.stopServiceLocationCoordinates ||
          unscheduledMarker.location,
        name:
          unscheduledMarker.title ||
          unscheduledMarker.stopTitle ||
          unscheduledMarker.name,
        address: unscheduledMarker.stopServiceLocation
          ? unscheduledMarker.stopServiceLocation.address
          : unscheduledMarker.address,
        serviceLocationId: unscheduledMarker.stopServiceLocation
          ? unscheduledMarker.stopServiceLocation.serviceLocationId
          : unscheduledMarker.serviceLocationId,
        routeId: selectedRoute.routeId,
        customerId: unscheduledMarker.stopServiceLocation
          ? unscheduledMarker.stopServiceLocation.customerId
          : unscheduledMarker.customerData.customerId,
        assigned: false,
        day: "none",
        dayOfTheWeek: "none",
        employeePhoto: "none",
        employeeUid: "none",
        employeeName: "none",
        originalDate: unscheduledMarker.originalDate || "none",
        originalEmployee: unscheduledMarker.originalEmployee || "none",
        unscheduledDates: unscheduledMarker.unscheduledDates || [],
        rescheduledDates: unscheduledMarker.rescheduledDates || [],
        type: unscheduledMarker.routeId ? "recStop" : "stop",
        markerId: markerId,
        stopId: unscheduledMarker.tempStopId
          ? unscheduledMarker.tempStopId
          : unscheduledMarker.stopId,
        customerName: unscheduledMarker.customer
          ? unscheduledMarker.customer.customerName
            ? unscheduledMarker.customer.customerName
            : unscheduledMarker.customer.name
          : unscheduledMarker.customerData.name,
        unassignedStopObject: unscheduledMarker,
        stopServiceLocation: unscheduledMarker.stopServiceLocation,
        stopTemplateId: unscheduledMarker.stopTemplateId,
        jobId: unscheduledMarker.jobId || "none",
        hidden: false,
      });
    }
    markers.current = addArr;

    for (let index = 0; index < addArr.length; index++) {
      const add = addArr[index];
      if (add.address === "600 Sarno Rd, Melbourne, FL 32935, USA") {
        console.log(add);
      }
      if (add.address === "602 Sarno Rd, Melbourne, FL 32935, USA") {
        console.log(add);
      }
    }

    setMarkersDataForProp(addArr);

    setRefresh(!refresh);
  };

  const handleApiLoaded = (map, maps) => {
    if (mapsObject === undefined && mapObject === undefined) {
      setMapsObject(maps);
      setMapObject(map);
    }
  };
  const handleOnHover = (value, index) => {
    console.log(value);
    if (value.multiStop) {
      if (
        multiStopMarkerData.value &&
        multiStopMarkerData.value.markerId === value.value.markerId
      ) {
        setMultiStopMarkerData({});
        setMultiStopMarkerDisplay(false);
        setSelectedSubMarker();
      } else {
        setMultiStopMarkerData(value);
        setHoverDisplay(false);
        setMultiStopMarkerDisplay(true);
        setHoverDisplayData({});
        setSelectedSubMarker();
      }
    } else {
      if (
        hoverDisplayData.value &&
        hoverDisplayData.value.markerId === value.value.markerId
      ) {
        setHoverDisplayData({});
        setHoverDisplay(false);
        setSelectedMarkerIndex();
      } else {
        setHoverDisplayData(value);
        setHoverDisplay(true);
        setSelectedMarkerIndex(index);
      }
    }
  };

  const handleToggleHiddenDay = (day, boolean, employee) => {
    if (boolean) {
      if (hiddenEmployees.includes(employee)) {
        setHiddenEmployees((prev) => prev.filter((a) => a !== employee));
      }
      for (let index = 0; index < markers.current.length; index++) {
        let marker = markers.current[index];

        if (marker.day === day && marker.employeeUid === employee) {
          markers.current[index].hidden = false;
          setRefresh(!refresh);
        }
      }
    } else {
      if (!hiddenEmployees.includes(employee)) {
        setHiddenEmployees((prev) => [...prev, employee]);
      }
      for (let index = 0; index < markers.current.length; index++) {
        let marker = markers.current[index];

        if (marker.day === day && marker.employeeUid === employee) {
          markers.current[index].hidden = true;
          setRefresh(!refresh);
        }
      }
    }
  };

  const handleAddUnscheduledStopToDb = (selectedEmployee, value, newDate) => {
    // need to do dates

    const stopId = generateBusinessId();

    const stopData = {
      dayOfTheWeek: dayjs(newDate).format("dddd"),
      frequency: "once",
      startOn: dayjs(newDate).format("MM/DD/YYYY"),
      stopAfter: "no end",
      name: value.stopTitle || value.unassignedStopObject.stopTitle,
      address: value.stopServiceLocation.address,
      stopId: stopId,
      routeId: selectedRoute.routeId,
      serviceLocationId: value.stopServiceLocation.serviceLocationId,
      completedDates: [],
      contractor: {
        id: selectedEmployee.uid,
        name: selectedEmployee.name,
        profilePhoto: selectedEmployee.photo,
      },
      customerData: {
        name: value.unassignedStopObject
          ? value.unassignedStopObject.customer.customerName
          : value.customer.name || value.customer.customerName,
        customerId: value.unassignedStopObject
          ? value.unassignedStopObject.customer.customerId
          : value.customer.customerId,
        businessId: value.stopServiceLocation.businessId,
      },
      businessId: value.stopServiceLocation.businessId,
      stopNickname: value.stopTitle || value.unassignedStopObject.stopTitle,
      location: value.stopServiceLocationCoordinates || value.location,
      stopTemplateId: "none",
      stopCustomerDescription:
        value.stopTitle || value.unassignedStopObject.stopTitle,
      stopWorkerDescription:
        value.stopDescription || value.unassignedStopObject.stopDescription,
      stopDate: dayjs(newDate).format("MM/DD/YYYY"),
      type: "Job Stop",
      messageId: "none",
      jobId: value.jobId,
    };

    db.collection("Businesses")
      .doc(value.stopServiceLocation.businessId)
      .get()
      .then((documentSnapshot) => {
        const businessData = documentSnapshot.data();

        db.collection("Businesses")
          .doc(value.stopServiceLocation.businessId)
          .collection("Jobs")
          .doc(value.jobId)
          .get()
          .then((documentSnapshot) => {
            const jobData = documentSnapshot.data();
            firebase
              .auth()
              .currentUser.getIdToken()
              .then((token) => {
                fetch(
                  "https://us-central1-symbri-production.cloudfunctions.net/sendCustomerNotification",
                  {
                    method: "POST",
                    body: JSON.stringify({
                      token: token,
                      notificationData: {
                        hasApp: {
                          title: "Job stop has been scheduled!",
                          body:
                            "The stop " +
                            (value.stopTitle ||
                              value.unassignedStopObject.stopTitle) +
                            " under the job " +
                            jobData.jobTitle +
                            " has been scheduled for " +
                            dayjs(newDate).format("MM/DD/YYYY") +
                            ".",
                        },
                        noApp: {
                          title: "Job stop been scheduled!",
                          body:
                            "The stop " +
                            value.stopTitle +
                            " under the job " +
                            jobData.jobTitle +
                            " has been scheduled. Please download the app and login into view, when the stop is scheduled for, the the stop details, and message " +
                            businessData.name +
                            " with any questions.",
                        },
                      },
                      customerId: value.unassignedStopObject
                        ? value.unassignedStopObject.customer.customerId
                        : value.customer.customerId,
                    }),
                  }
                );
              });
          });
      });

    db.collection("Customers")
      .doc(
        value.unassignedStopObject
          ? value.unassignedStopObject.customer.customerId
          : value.customer.customerId
      )
      .collection("ServiceLocations")
      .doc(value.stopServiceLocation.serviceLocationId)
      .update({
        routes: firebase.firestore.FieldValue.arrayUnion(stopData),
      });

    const routeData = {
      frequency: "once",
      name: value.stopTitle || value.unassignedStopObject.stopTitle,
      address: value.stopServiceLocation.address,
      stopObject: stopData,
      routeId: stopId,
      jobId: value.jobId,
      serviceLocationId: value.stopServiceLocation.serviceLocationId,
      type: "stop",
      completed: false,
      stopId: stopId,
      contractor: selectedEmployee,
      location: value.stopServiceLocationCoordinates || value.location,
      customerData: {
        name: value.unassignedStopObject
          ? value.unassignedStopObject.customer.customerName
          : value.customer.name || value.customer.customerName,
        customerId: value.unassignedStopObject
          ? value.unassignedStopObject.customer.customerId
          : value.customer.customerId,
        businessId: value.stopServiceLocation.businessId,
      },
    };

    db.collection("Businesses")
      .doc(value.stopServiceLocation.businessId)
      .collection("Workers")
      .doc(selectedEmployee.uid)
      .collection("Route")
      .doc("JobStops")
      .set(
        {
          route: firebase.firestore.FieldValue.arrayUnion(routeData),
          checkDistanceTime: true,
        },
        { merge: true }
      );

    db.collection("Businesses")
      .doc(value.stopServiceLocation.businessId)
      .collection("Jobs")
      .doc(value.jobId)
      .update({
        stops: firebase.firestore.FieldValue.arrayRemove(value),
        unscheduledStops: firebase.firestore.FieldValue.arrayRemove(value),
      })
      .then(() => {
        db.collection("Businesses")
          .doc(value.stopServiceLocation.businessId)
          .collection("Jobs")
          .doc(value.jobId)
          .update({
            stops: firebase.firestore.FieldValue.arrayUnion(stopData),
            status: "scheduled",
          })
          .then(() => {
            setScheduleOneTimeStopDisplay(false);
            handleReloadEntireRoute();
          });
      });
  };

  const handleAddUnscheduledStopToDay = (value, allEmployees) => {
    setSelectedUnscheduledStop(value);

    // need a way to select the contractor you want to add to
    // then use selected date as the date
    // just do a popup with a list of workers to choose from maybe?
  };

  window.addEventListener("resize", (event) => {
    setMapWidth(document.getElementsByClassName("py-6")[0].offsetWidth + "px");
  });

  const handleOpenReschedule = (stopData) => {
    if (stopData.stopObject) {
      setViewDetailsOneTimeDisplay(false);
      setViewDetailsRecDisplay(false);
      setOneTimeRescheduleDisplay(true);
      setRescheduleData(stopData);
      setHoverDisplayData({});
      setHoverDisplay(false);
    } else {
      setViewDetailsOneTimeDisplay(false);
      setViewDetailsRecDisplay(false);
      setRescheduleDisplay(true);
      setRescheduleData(stopData);
      setHoverDisplayData({});
      setHoverDisplay(false);
    }
  };

  const handleOpenSchedule = (stopData) => {
    setRescheduleData(stopData);
    setViewDetailsOneTimeDisplay(false);
    setScheduleOneTimeStopDisplay(true);
  };

  const handleViewDetails = (data) => {
    if (data.unassignedStopObject) {
      setViewDetailsData(data.unassignedStopObject);
    } else {
      setViewDetailsData(data);
    }

    setHoverDisplayData({});
    setHoverDisplay(false);
    setSelectedMarkerIndex();
    if (data.type === "Reoccurring Stop" || data.type === "recStop") {
      setViewDetailsOneTimeDisplay(false);
      setViewDetailsRecDisplay(true);
    } else if (data.type === "stop") {
      setViewDetailsOneTimeDisplay(true);
      setViewDetailsRecDisplay(false);
    } else if (data.stopServiceLocation) {
      setViewDetailsOneTimeDisplay(true);
      setViewDetailsRecDisplay(false);
    }
  };

  const handleUpdateRouteOrder = (newOrder) => {
    routeOrder.current = newOrder;
  };

  const handleCompleteRecTimeStop = async (stop, date, workerUid) => {
    await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Workers")
      .doc(workerUid)
      .collection("Route")
      .doc(dayjs(date).format("dddd"))
      .collection("Stops")
      .doc(stop.tempStopId ? stop.tempStopId : stop.stopId)
      .update({
        completedDates: firebase.firestore.FieldValue.arrayUnion(
          dayjs(date).format("MM/DD/YYYY")
        ),
      });

    const customerServiceLocationDoc = await db
      .collection("Customers")
      .doc(stop.customerData.customerId)
      .collection("ServiceLocations")
      .doc(stop.serviceLocationId)
      .get();

    const customerServiceLocationData = customerServiceLocationDoc.data();

    let routes = customerServiceLocationData.routes;

    let indexOfRoute = routes.findIndex((a) =>
      a.tempStopId
        ? a.tempStopId
        : a.stopId === stop.tempStopId
        ? stop.tempStopId
        : stop.stopId
    );

    routes[indexOfRoute].completedDates.push(dayjs(date).format("MM/DD/YYYY"));

    await db
      .collection("Customers")
      .doc(stop.customerData.customerId)
      .collection("ServiceLocations")
      .doc(stop.serviceLocationId)
      .update({
        routes: routes,
      });

    setViewDetailsRecDisplay(false);
    toast.success("Stop Completed");
    handleReloadEntireRoute();
  };

  const handleCompleteOneTimeStop = (stop, date, workerUid) => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Workers")
      .doc(workerUid)
      .collection("Route")
      .doc("JobStops")
      .get()
      .then((jobStopsDocumentSnapshot) => {
        const jobStopsDoc = jobStopsDocumentSnapshot.data();
        let stops = jobStopsDoc.route;

        let indexOfStops = stops.findIndex(
          (a) => a.stopObject.stopId === stop.stopObject.stopId
        );

        stops[indexOfStops].stopObject.completedDates.push(
          dayjs(date).format("MM/DD/YYYY")
        );

        stops[indexOfStops].completed = true;

        db.collection("Businesses")
          .doc(selectedBusiness)
          .collection("Workers")
          .doc(workerUid)
          .collection("Route")
          .doc("JobStops")
          .update({ route: stops })
          .then(async () => {
            const jobDoc = await db
              .collection("Businesses")
              .doc(selectedBusiness)
              .collection("Jobs")
              .doc(stop.stopObject.jobId)
              .get();

            const jobData = jobDoc.data();

            let stops = jobData.stops;
            let indexOfStops = stops.findIndex(
              (a) => a.stopId === stop.stopObject.stopId
            );
            stops[indexOfStops].completedDates.push(
              dayjs(date).format("MM/DD/YYYY")
            );

            db.collection("Businesses")
              .doc(selectedBusiness)
              .collection("Jobs")
              .doc(stop.stopObject.jobId)
              .update({
                stops: stops,
                completedDates: firebase.firestore.FieldValue.arrayUnion(
                  dayjs(date).format("MM/DD/YYYY")
                ),
              })
              .then(() => {
                setViewDetailsOneTimeDisplay(false);
                handleReloadEntireRoute();
              })
              .catch((e) => console.log(e));
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };

  const handleViewDay = (data, employee, completedStops) => {
    if (orderListener.current) {
      orderListener.current();
    }

    orderListener.current = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Workers")
      .doc(employee.uid)
      .collection("Route")
      .doc(dayjs(routeStartDate).format("dddd"))
      .onSnapshot((workerRouteDocumentSnapshot) => {
        if (workerRouteDocumentSnapshot.exists) {
          const workerRouteDoc = workerRouteDocumentSnapshot.data();
          if (
            workerRouteDoc.customRouteOrders &&
            workerRouteDoc.customRouteOrders.some(
              (a) => a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
            )
          ) {
            const order =
              workerRouteDoc.customRouteOrders[
                workerRouteDoc.customRouteOrders.findIndex(
                  (a) => a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
                )
              ].order;

            handleUpdateRouteOrder(order);
            setCustomerRouteOrder(order);
          } else {
            let order = [];
            for (let index = 0; index < data.length; index++) {
              const stop = data[index];
              order.push(stop.tempStopId ? stop.tempStopId : stop.stopId);
            }
            setCustomerRouteOrder(order);
          }
          setDayViewData({
            data: data,
            employee: employee,
            completedStops: completedStops,
          });
          setDayViewDisplay(true);
        } else {
          setDayViewData({
            data: data,
            employee: employee,
            completedStops: completedStops,
          });
          setDayViewDisplay(true);
        }
      });
  };

  return (
    <div
      style={{
        height: "88%",
      }}
    >
      <div>
        <SideMenu
          handleOpenReschedule={handleOpenReschedule}
          selectedBusiness={selectedBusiness}
          selectedRoute={selectedRoute}
          serviceLocationsInRoute={serviceLocationsInRoute}
          setServiceLocationsInRoute={setServiceLocationsInRoute}
          handleToggleHiddenDay={handleToggleHiddenDay}
          setRouteStartDate={setRouteStartDate}
          hasUnactivatedChanges={hasUnactivatedChanges}
          routeStartDate={routeStartDate}
          setHasUnactivatedChanges={setHasUnactivatedChanges}
          setUnscheduledMarkers={setUnscheduledMarkers}
          setSelectedUnscheduledStop={setSelectedUnscheduledStop}
          selectedUnscheduledStop={selectedUnscheduledStop}
          handleAddUnscheduledStopToDay={handleAddUnscheduledStopToDay}
          reloadUnscheduledStops={reloadUnscheduledStops}
          rescheduledStops={rescheduledStops}
          handleViewDetails={handleViewDetails}
          setSelectedUnscheduledStops={setSelectedUnscheduledStops}
          selectedUnscheduledStops={selectedUnscheduledStops}
          setRefresh={setRefresh}
          customRouteOrder={customRouteOrder}
          handleUpdateRouteOrder={handleUpdateRouteOrder}
          unscheduledMarkers={unscheduledMarkers}
          handleViewDay={handleViewDay}
          setDayViewData={setDayViewData}
          setDayViewDisplay={setDayViewDisplay}
          dayViewDisplay={dayViewDisplay}
          dayViewData={dayViewData}
          setDayData={setDayData}
          dayData={dayData}
          forceEmployeeCardsReload={forceEmployeeCardsReload}
          allEmployees={allEmployees}
          markersDataForProp={markersDataForProp}
          rescheduledMarkers={rescheduledMarkers}
          hiddenEmployees={hiddenEmployees}
        />

        <div>
          {defaultZoom && mapCenterCords && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN,
                libraries: ["places"],
              }}
              options={{
                fullscreenControl: false,
                zoomControl: true,
                clickableIcons: false,
              }}
              defaultCenter={{
                lat: mapCenterCords.lat,
                lng: mapCenterCords.lng,
              }}
              defaultZoom={defaultZoom}
              onChange={({ center, zoom }) => {
                // Here you can access the new zoom value
                handleZoomValue(zoom);
              }}
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              yesIWantToUseGoogleMapApiInternals
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                width: mapWidth,
                height: "100vh",
              }}
            >
              {getAdjustedLongitude(
                markers.current,
                props,
                routeStartDate,
                selectedUnscheduledStops
              )
                .filter((a) =>
                  props.searchTerm.length === 0
                    ? true
                    : a.customerName
                        .toLowerCase()
                        .includes(props.searchTerm.toLowerCase())
                )
                .filter((a) =>
                  !a.assigned
                    ? selectedUnscheduledStops.some(
                        (b) =>
                          (b.tempStopId ? b.tempStopId : b.stopId) ===
                          (a.tempStopId ? a.tempStopId : a.stopId)
                      )
                    : true
                )
                .filter((a) =>
                  a.assigned && a.unscheduledDates
                    ? !a.unscheduledDates.includes(
                        dayjs(routeStartDate).format("MM/DD/YYYY")
                      )
                    : true
                )
                .filter((a) =>
                  a.tempStopId
                    ? a.date === dayjs(routeStartDate).format("MM/DD/YYYY")
                    : true
                )
                .map((value, index) => (
                  <div
                    lat={value.location.lat}
                    lng={value.location.lng}
                    key={value.tempStopId ? value.tempStopId : value.stopId}
                    onClick={() =>
                      handleOnHover(
                        markers.current
                          .filter(
                            (a) =>
                              a.serviceLocationId === value.serviceLocationId &&
                              !a.hidden
                          )
                          .filter((a) =>
                            props.searchTerm.length === 0
                              ? true
                              : a.address
                                  .toLowerCase()
                                  .includes(props.searchTerm.toLowerCase())
                          )
                          .filter((a) =>
                            !a.assigned
                              ? selectedUnscheduledStops.some(
                                  (b) =>
                                    (b.tempStopId ? b.tempStopId : b.stopId) ===
                                    (a.tempStopId ? a.tempStopId : a.stopId)
                                )
                              : true
                          )
                          .filter((a) =>
                            a.unscheduledDates
                              ? !a.unscheduledDates.includes(
                                  dayjs(routeStartDate).format("MM/DD/YYYY")
                                )
                              : true
                          )
                          .filter((a) =>
                            a.tempStopId
                              ? a.date ===
                                dayjs(routeStartDate).format("MM/DD/YYYY")
                              : true
                          ).length > 1
                          ? {
                              multiStop: true,
                              value: markers.current
                                .filter(
                                  (a) =>
                                    a.serviceLocationId ===
                                      value.serviceLocationId && !a.hidden
                                )
                                .filter((a) =>
                                  props.searchTerm.length === 0
                                    ? true
                                    : a.address
                                        .toLowerCase()
                                        .includes(
                                          props.searchTerm.toLowerCase()
                                        )
                                )
                                .filter((a) =>
                                  !a.assigned
                                    ? selectedUnscheduledStops.some(
                                        (b) =>
                                          (b.tempStopId
                                            ? b.tempStopId
                                            : b.stopId) ===
                                          (a.tempStopId
                                            ? a.tempStopId
                                            : a.stopId)
                                      )
                                    : true
                                )
                                .filter((a) =>
                                  a.unscheduledDates
                                    ? !a.unscheduledDates.includes(
                                        dayjs(routeStartDate).format(
                                          "MM/DD/YYYY"
                                        )
                                      )
                                    : true
                                )
                                .filter((a) =>
                                  a.tempStopId
                                    ? a.date ===
                                      dayjs(routeStartDate).format("MM/DD/YYYY")
                                    : true
                                ),
                            }
                          : { multiStop: false, value: value },
                        index
                      )
                    }
                    style={{
                      display: value.hidden ? "none" : "flex",
                      width: 40,
                      height: 40,
                      marginLeft: -20,
                      border: "4px solid rgb(41, 186, 230)",
                      borderColor:
                        selectedMarkerIndex === index
                          ? "red"
                          : markers.current
                              .filter(
                                (a) =>
                                  a.serviceLocationId ===
                                    value.serviceLocationId && !a.hidden
                              )
                              .filter((a) =>
                                props.searchTerm.length === 0
                                  ? true
                                  : a.address
                                      .toLowerCase()
                                      .includes(props.searchTerm.toLowerCase())
                              )
                              .filter((a) =>
                                !a.assigned
                                  ? selectedUnscheduledStops.some(
                                      (b) =>
                                        (b.tempStopId
                                          ? b.tempStopId
                                          : b.stopId) ===
                                        (a.tempStopId ? a.tempStopId : a.stopId)
                                    )
                                  : true
                              )
                              .filter((a) =>
                                a.unscheduledDates
                                  ? !a.unscheduledDates.includes(
                                      dayjs(routeStartDate).format("MM/DD/YYYY")
                                    )
                                  : true
                              )
                              .filter((a) =>
                                a.tempStopId
                                  ? a.date ===
                                    dayjs(routeStartDate).format("MM/DD/YYYY")
                                  : true
                              ).length > 1
                          ? "black"
                          : value.day === "Monday"
                          ? "#976DD0"
                          : value.day === "Tuesday"
                          ? "#FFBA5C"
                          : value.day === "Wednesday"
                          ? "#77D353"
                          : value.day === "Thursday"
                          ? "#F95F62"
                          : value.day === "Friday"
                          ? "#235789"
                          : value.day === "Saturday"
                          ? "#ED7D3A"
                          : value.day === "Sunday"
                          ? "blue"
                          : "black",

                      borderRadius: 50,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {multiStopMarkerDisplay &&
                      multiStopMarkerData.value[0] &&
                      multiStopMarkerData.value.some(
                        (a) => a.markerId === value.markerId
                      ) && (
                        <Popover
                          refresh={refresh}
                          handleSelectSubMarker={handleSelectSubMarker}
                          stopCount={value.stopCount}
                          multiStopMarkerData={multiStopMarkerData}
                          selectedSubMarker={selectedSubMarker}
                          markers={markers.current
                            .filter(
                              (a) =>
                                a.serviceLocationId ===
                                  value.serviceLocationId && !a.hidden
                            )
                            .filter((a) =>
                              props.searchTerm.length === 0
                                ? true
                                : a.address
                                    .toLowerCase()
                                    .includes(props.searchTerm.toLowerCase())
                            )
                            .filter((a) =>
                              !a.assigned
                                ? selectedUnscheduledStops.some(
                                    (b) =>
                                      (b.tempStopId
                                        ? b.tempStopId
                                        : b.stopId) ===
                                      (a.tempStopId ? a.tempStopId : a.stopId)
                                  )
                                : true
                            )
                            .filter((a) =>
                              a.unscheduledDates
                                ? !a.unscheduledDates.includes(
                                    dayjs(routeStartDate).format("MM/DD/YYYY")
                                  )
                                : true
                            )
                            .filter((a) =>
                              a.tempStopId
                                ? a.date ===
                                  dayjs(routeStartDate).format("MM/DD/YYYY")
                                : true
                            )}
                          //hiddenMarkers={hiddenMarkers}
                        />
                      )}
                    <div
                      style={{
                        display: "flex",
                        width: 32,
                        height: 32,
                        borderRadius: 50,
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor:
                          markers.current
                            .filter(
                              (a) =>
                                a.serviceLocationId ===
                                  value.serviceLocationId && !a.hidden
                            )
                            .filter((a) =>
                              props.searchTerm.length === 0
                                ? true
                                : a.address
                                    .toLowerCase()
                                    .includes(props.searchTerm.toLowerCase())
                            )
                            .filter((a) =>
                              !a.assigned
                                ? selectedUnscheduledStops.some(
                                    (b) =>
                                      (b.tempStopId
                                        ? b.tempStopId
                                        : b.stopId) ===
                                      (a.tempStopId ? a.tempStopId : a.stopId)
                                  )
                                : true
                            )
                            .filter((a) =>
                              a.unscheduledDates
                                ? !a.unscheduledDates.includes(
                                    dayjs(routeStartDate).format("MM/DD/YYYY")
                                  )
                                : true
                            )
                            .filter((a) =>
                              a.tempStopId
                                ? a.date ===
                                  dayjs(routeStartDate).format("MM/DD/YYYY")
                                : true
                            ).length > 1
                            ? "white"
                            : value.assigned
                            ? "gray"
                            : "gray",
                        color: "white",
                        fontSize: 10,
                      }}
                    >
                      {markers.current
                        .filter(
                          (a) =>
                            a.serviceLocationId === value.serviceLocationId &&
                            !a.hidden
                        )
                        .filter((a) =>
                          props.searchTerm.length === 0
                            ? true
                            : a.address
                                .toLowerCase()
                                .includes(props.searchTerm.toLowerCase())
                        )
                        .filter((a) =>
                          !a.assigned
                            ? selectedUnscheduledStops.some(
                                (b) =>
                                  (b.tempStopId ? b.tempStopId : b.stopId) ===
                                  (a.tempStopId ? a.tempStopId : a.stopId)
                              )
                            : true
                        )
                        .filter((a) =>
                          a.unscheduledDates
                            ? !a.unscheduledDates.includes(
                                dayjs(routeStartDate).format("MM/DD/YYYY")
                              )
                            : true
                        )
                        .filter((a) =>
                          a.tempStopId
                            ? a.date ===
                              dayjs(routeStartDate).format("MM/DD/YYYY")
                            : true
                        ).length <= 1 &&
                        value.assigned && (
                          <div>
                            {value.employeeName
                              .match(/(\b\S)?/g)
                              .join("")
                              .match(/(^\S|\S$)?/g)
                              .join("")
                              .toUpperCase()}
                          </div>
                        )}

                      {markers.current
                        .filter(
                          (a) =>
                            a.serviceLocationId === value.serviceLocationId &&
                            !a.hidden
                        )
                        .filter((a) =>
                          props.searchTerm.length === 0
                            ? true
                            : a.address
                                .toLowerCase()
                                .includes(props.searchTerm.toLowerCase())
                        )
                        .filter((a) =>
                          !a.assigned
                            ? selectedUnscheduledStops.some(
                                (b) =>
                                  (b.tempStopId ? b.tempStopId : b.stopId) ===
                                  (a.tempStopId ? a.tempStopId : a.stopId)
                              )
                            : true
                        )
                        .filter((a) =>
                          a.unscheduledDates
                            ? !a.unscheduledDates.includes(
                                dayjs(routeStartDate).format("MM/DD/YYYY")
                              )
                            : true
                        )
                        .filter((a) =>
                          a.tempStopId
                            ? a.date ===
                              dayjs(routeStartDate).format("MM/DD/YYYY")
                            : true
                        ).length > 1 && (
                        <div>
                          <MarkerDots
                            refresh={refresh}
                            markers={markers.current
                              .filter(
                                (a) =>
                                  a.serviceLocationId ===
                                    value.serviceLocationId && !a.hidden
                              )
                              .filter((a) =>
                                props.searchTerm.length === 0
                                  ? true
                                  : a.address
                                      .toLowerCase()
                                      .includes(props.searchTerm.toLowerCase())
                              )
                              .filter((a) =>
                                !a.assigned
                                  ? selectedUnscheduledStops.some(
                                      (b) =>
                                        (b.tempStopId
                                          ? b.tempStopId
                                          : b.stopId) ===
                                        (a.tempStopId ? a.tempStopId : a.stopId)
                                    )
                                  : true
                              )
                              .filter((a) =>
                                a.unscheduledDates
                                  ? !a.unscheduledDates.includes(
                                      dayjs(routeStartDate).format("MM/DD/YYYY")
                                    )
                                  : true
                              )
                              .filter((a) =>
                                a.tempStopId
                                  ? a.date ===
                                    dayjs(routeStartDate).format("MM/DD/YYYY")
                                  : true
                              )}
                            value={value}
                            //hiddenMarkers={hiddenMarkers}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </GoogleMapReact>
          )}
          {hoverDisplay && (
            <div
              style={{
                width: "200px",
                border: "none",
                borderRadius: 5,
                backgroundColor: "transparent",
                height: "auto",
                position: "absolute",
                zIndex: 1000,
                margin: 20,
                marginTop: "2%",
                right: 20,
                top: 80,
              }}
              class="routeBuilderEditSideMenuScroll"
            >
              {hoverDisplayData.multiStop && (
                <div>
                  {hoverDisplayData.value.map((value) => (
                    <StopDetailsCard
                      selectedDate={routeStartDate}
                      value={value}
                      handleViewDetails={handleViewDetails}
                      handleViewDay={handleViewDay}
                      dayData={dayData}
                      handleOpenReschedule={handleOpenReschedule}
                      originalDate={dayjs(routeStartDate).format("MM/DD/YYYY")}
                      handleReloadEntireRoute={handleReloadEntireRoute}
                      setHoverDisplay={setHoverDisplay}
                    />
                  ))}
                </div>
              )}
              {!hoverDisplayData.multiStop && (
                <StopDetailsCard
                  selectedDate={routeStartDate}
                  value={hoverDisplayData.value}
                  handleViewDetails={handleViewDetails}
                  handleViewDay={handleViewDay}
                  dayData={dayData}
                  handleOpenReschedule={handleOpenReschedule}
                  originalDate={dayjs(routeStartDate).format("MM/DD/YYYY")}
                  handleReloadEntireRoute={handleReloadEntireRoute}
                  setHoverDisplay={setHoverDisplay}
                />
              )}
            </div>
          )}
        </div>
        <div
          onClick={handleReloadEntireRoute}
          style={{
            position: "absolute",
            right: "2%",
            top: "10%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            cursor: "pointer",
            backgroundColor: "white",
            padding: 8,
          }}
        >
          <div style={{ fontSize: 12, marginRight: 4 }}>Perform Sync</div>
          <MdSync />
        </div>

        {routeHasProcessingChanges && (
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: mapWidth,
              height: "100vh",
              zIndex: 99999,
              backgroundColor: "rgba(0,0,0,0.5)",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                marginRight: 4,
                color: "white",
                backgroundColor: "rgba(0,0,0,0.6)",
                borderRadius: 10,
                padding: 20,
              }}
            >
              <div style={{ fontSize: 36 }}>Route is currently pending</div>
              <div style={{ fontSize: 14, textAlign: "center" }}>
                You recently activated a new route which is processing,
              </div>
              <div style={{ fontSize: 14, textAlign: "center" }}>
                this can take up to 2 minutes.
              </div>
            </div>
          </div>
        )}
      </div>

      <RecheduleRecurringStop
        rescheduleData={rescheduleData}
        setOpen={setRescheduleDisplay}
        open={rescheduleDisplay}
        selectedBusiness={selectedBusiness}
        routeId={selectedRoute.routeId}
        originalDate={dayjs(routeStartDate).format("MM/DD/YYYY")}
        setRouteStartDate={setRouteStartDate}
        handleReloadEntireRoute={handleReloadEntireRoute}
      />

      <RescheduleOneTimeStop
        rescheduleData={rescheduleData}
        setOpen={setOneTimeRescheduleDisplay}
        open={oneTimeRescheduleDisplay}
        selectedBusiness={selectedBusiness}
        routeId={selectedRoute.routeId}
        originalDate={dayjs(routeStartDate).format("MM/DD/YYYY")}
        setRouteStartDate={setRouteStartDate}
        handleReloadEntireRoute={handleReloadEntireRoute}
      />

      <ScheduleOneTimeStop
        rescheduleData={rescheduleData}
        setOpen={setScheduleOneTimeStopDisplay}
        open={scheduleOneTimeStopDisplay}
        selectedBusiness={selectedBusiness}
        routeId={selectedRoute.routeId}
        originalDate={dayjs(routeStartDate).format("MM/DD/YYYY")}
        setRouteStartDate={setRouteStartDate}
        handleAddUnscheduledStopToDb={handleAddUnscheduledStopToDb}
      />

      <ViewDetailsRec
        setOpen={setViewDetailsRecDisplay}
        open={viewDetailsRecDisplay}
        viewDetailsData={viewDetailsData}
        stopDate={dayjs(
          viewDetailsData?.date || viewDetailsData?.originalDate
        ).format("MM/DD/YYYY")}
        handleOpenReschedule={handleOpenReschedule}
        handleCompleteRecTimeStop={handleCompleteRecTimeStop}
        setDayData={setDayData}
        dayData={dayData}
        handleViewDay={handleViewDay}
        selectedDate={routeStartDate}
      />

      <ViewDetailsOneTime
        setOpen={setViewDetailsOneTimeDisplay}
        open={viewDetailsOneTimeDisplay}
        viewDetailsData={viewDetailsData}
        stopDate={dayjs(routeStartDate).format("MM/DD/YYYY")}
        handleOpenReschedule={handleOpenReschedule}
        handleOpenSchedule={handleOpenSchedule}
        handleCompleteOneTimeStop={handleCompleteOneTimeStop}
        setDayData={setDayData}
        dayData={dayData}
        handleViewDay={handleViewDay}
      />
    </div>
  );
};

export default ActiveRoute;
