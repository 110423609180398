import {
  CheckIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from "@heroicons/react/solid";
import { GoNote } from "react-icons/go";
import { useEffect, useState } from "react";
import ServiceLocationAddButton from "./ServiceLocationAddButton";
import ServiceLocationJobs from "./ServiceLocationJobs";
import firebase from "firebase/compat/app";
import { generateBusinessId } from "../Tools/generateBusinessId";
import ServiceLocationStops from "./ServiceLocationStops";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import ServiceLocationNewJob from "./ServiceLocationNewJob";
import ServiceLocationAddSubscription from "./ServiceLocationAddSubscription";
import ServiceLocationSubscriptions from "./ServiceLocationSubscriptions";
import ServiceLocationSubscriptionView from "./ServiceLocationSubscriptionView";
import ServiceLocationMetrics from "./ServiceLocationMetrics";
import Geocoder from "react-native-geocoding";
import ServiceLocationAddOneTimeStop from "./ServiceLocationAddOneTimeStop";

Geocoder.init("AIzaSyAdCqyznNDDWC84JmiS6DouRrGgHnlSzAg");

const eventTypes = {
  applied: { icon: UserIcon, bgColorClass: "bg-gray-400" },
  advanced: { icon: QuestionMarkCircleIcon, bgColorClass: "bg-blue-500" },
  completed: { icon: CheckIcon, bgColorClass: "bg-green-500" },
};

export default function ServiceLocationProfile(props) {
  const serviceLocation = props.selectedServiceLocation;
  const [newNoteText, setNewNoteText] = useState("");
  const [notes, setNotes] = useState(serviceLocation.notes);
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [poolDetails, setPoolDetails] = useState([]);
  const [newJobDisplay, setNewJobDisplay] = useState(false);
  const [addSubscriptionDisplay, setAddSubscriptionDisplay] = useState(false);
  const [pausedSubscriptions, setPausedSubscriptions] = useState([]);
  const [viewSubscriptionDisplay, setViewSubscriptionDisplay] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState();
  const [createOneTimeStopDisplay, setCreateOneTimeStopDisplay] =
    useState(false);
  const [accessCode, setAccessCode] = useState(
    serviceLocation.propertyAccessCodes
  );
  const [pets, setPets] = useState(serviceLocation.pets);

  const db = firebase.firestore();

  const handleAddNote = () => {
    if (newNoteText === "" || newNoteText.trim().length === 0) {
      toast.warn("Note field cannot be blank!");
      return;
    }
    db.collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then((documentSnapshot) => {
        const data = documentSnapshot.data();
        db.collection("Customers")
          .doc(serviceLocation.customerId)
          .collection("ServiceLocations")
          .doc(serviceLocation.serviceLocationId)
          .update({
            notes: firebase.firestore.FieldValue.arrayUnion({
              noteBody: newNoteText,
              noteFrom: data.name,
              noteId: generateBusinessId(),
              noteWhen: new Date(),
            }),
          })
          .then(() => {
            let currentNotes = notes;
            currentNotes.push({
              noteBody: newNoteText,
              noteFrom: data.name,
              noteId: generateBusinessId(),
              noteWhen: new Date(),
            });
            setNotes(currentNotes);
            console.log(newNoteText);
            setNewNoteText("");
          });
      });
  };

  useEffect(() => {
    setPoolDetails([
      { name: "Base CYA", value: serviceLocation.baseCyaLevels },
      { name: "TDS", value: serviceLocation.tds },
      { name: "Phosphates", value: serviceLocation.phosphates },
      { name: "Salt", value: serviceLocation.salt },
      { name: "Last Water Change", value: serviceLocation.lastWaterChange },
      { name: "Filter Type", value: serviceLocation.pumpType },
      { name: "Age Of Grids", value: serviceLocation.pumpType },
      { name: "Last Backwash", value: serviceLocation.pumpType },
      { name: "Last Filter Clean", value: serviceLocation.lastCleaning },
      { name: "Chlorinator", value: serviceLocation.chlorinator },
      { name: "Polaris", value: serviceLocation.polaris },
      { name: "Borates", value: serviceLocation.borates },
      { name: "Spa", value: serviceLocation.spa },
      { name: "Automation", value: serviceLocation.automation },
      { name: "Travel Fee", value: serviceLocation.travelFee },
      { name: "Pool Surface", value: serviceLocation.poolSurface },
    ]);
  }, [serviceLocation]);

  const handleViewSubscription = (sub) => {
    setSelectedSubscription(sub);
    setViewSubscriptionDisplay(true);
    props.handleAddToBreadcrumb({
      name: "Subscriptions",
      type: "serviceLocation",
      current: true,
    });
  };

  const handleReloadData = async () => {
    const serviceLocationDoc = await db
      .collection("Customers")
      .doc(serviceLocation.customerId)
      .collection("ServiceLocations")
      .doc(serviceLocation.serviceLocationId)
      .get();

    const subscriptionObject = serviceLocationDoc.data().subscriptions;

    setPausedSubscriptions(serviceLocationDoc.data().pausedSubscriptions || []);

    setSubscriptionDetails(subscriptionObject);

    setReload(!reload);
  };

  const cancelScheduled = async (subscriptionId) => {
    const customerServiceLocationDoc = await db
      .collection("Customers")
      .doc(serviceLocation.customerId)
      .collection("ServiceLocations")
      .doc(serviceLocation.serviceLocationId)
      .get();

    let pausedSubscriptions =
      customerServiceLocationDoc.data().pausedSubscriptions;
    console.log(pausedSubscriptions);
    pausedSubscriptions = pausedSubscriptions.filter(
      (a) => a.subscriptionId !== subscriptionId
    );

    await db
      .collection("Customers")
      .doc(serviceLocation.customerId)
      .collection("ServiceLocations")
      .doc(serviceLocation.serviceLocationId)
      .update({
        scheduledSubscription: firebase.firestore.FieldValue.delete(),
        pausedSubscriptions: pausedSubscriptions,
      });

    setPausedSubscriptions(pausedSubscriptions);

    setReload(!reload);
  };

  const updateAccessCode = (change) => {
    console.log(change);
    setAccessCode(change);
    db.collection("Customers")
      .doc(serviceLocation.customerId)
      .collection("ServiceLocations")
      .doc(serviceLocation.serviceLocationId)
      .update({ propertyAccessCodes: change })
      .catch((e) => console.log(e));
  };

  const updatePets = (change) => {
    console.log(change);
    setPets(change);
    db.collection("Customers")
      .doc(serviceLocation.customerId)
      .collection("ServiceLocations")
      .doc(serviceLocation.serviceLocationId)
      .update({ pets: change })
      .catch((e) => console.log(e));
  };

  const handleResumeSubscription = (subscriptionId, subscriptionStopId) => {
    firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        fetch(
          "https://us-central1-symbri-production.cloudfunctions.net/handleResumeSubscription",
          {
            method: "POST",
            body: JSON.stringify({
              token: token,
              serviceLocationId: serviceLocation.serviceLocationId,
              customerId: serviceLocation.customerId,
              subscriptionId: subscriptionId,
              subscriptionStopId: subscriptionStopId,
            }),
          }
        );
      });
  };

  return viewSubscriptionDisplay ? (
    <ServiceLocationSubscriptionView
      selectedCustomer={props.selectedCustomer}
      selectedSubscription={selectedSubscription}
      businessId={serviceLocation.businessId}
      serviceLocation={serviceLocation}
    />
  ) : (
    <>
      <div className="min-h-full">
        <main className="py-10 p-44">
          {/* Page header */}
          <div className="max-w-4xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-full lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={
                      serviceLocation.photos[0] ||
                      "https://firebasestorage.googleapis.com/v0/b/symbri-production.appspot.com/o/poolDefault.png?alt=media&token=c009a4d6-e644-4890-9dce-7e61c85c40c7"
                    }
                    alt=""
                  />
                  <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  {serviceLocation.address.split(", USA")[0]}
                </h1>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              <div
                onClick={() => setCreateOneTimeStopDisplay(true)}
                className="bg-[#4F46E5] text-white w-40 h-10 rounded-md flex items-center justify-center cursor-pointer"
              >
                + One Time Stop
              </div>
            </div>
          </div>

          <div className="mt-8 max-w-4xl mx-auto gap-6 sm:px-6 lg:max-w-full lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Access Code
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <input
                            onChange={(change) =>
                              updateAccessCode(change.target.value)
                            }
                            value={accessCode}
                          />
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Pets
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <input
                            onChange={(change) =>
                              updatePets(change.target.value)
                            }
                            value={pets}
                          />
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </section>

              {/* Subscriptions*/}
              <section>
                <ServiceLocationSubscriptions
                  serviceLocation={serviceLocation}
                  subscriptionDetails={subscriptionDetails}
                  pausedSubscriptions={pausedSubscriptions}
                  businessId={serviceLocation.businessId}
                  handleResumeSubscription={handleResumeSubscription}
                  setAddSubscriptionDisplay={setAddSubscriptionDisplay}
                  handleReloadData={handleReloadData}
                  cancelScheduled={cancelScheduled}
                  key={reload}
                  handleViewSubscription={handleViewSubscription}
                />
              </section>

              {/* Stops*/}
              <section>
                <ServiceLocationStops
                  serviceLocation={serviceLocation}
                  routes={serviceLocation.routes}
                  businessId={serviceLocation.businessId}
                />
              </section>

              {/* Jobs*/}
              <section>
                <ServiceLocationJobs
                  businessId={serviceLocation.businessId}
                  serviceLocation={serviceLocation}
                  jobs={props.jobs}
                />
              </section>
              {/* Metrics */}
              <section>
                <ServiceLocationMetrics
                  businessId={serviceLocation.businessId}
                  serviceLocation={serviceLocation}
                  jobs={props.jobs}
                />
              </section>
              {/* Comments*/}
              <section aria-labelledby="notes-title">
                <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden border-gray-200 border-2">
                  <div className="divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6 bg-gray-50">
                      <h2
                        id="notes-title"
                        className="text-lg font-medium text-gray-900"
                      >
                        Notes
                      </h2>
                    </div>
                    <div className="px-4 py-6 sm:px-6 max-h-80 overflow-y-scroll">
                      <ul role="list" className="space-y-8">
                        {serviceLocation.notes.map((note) => (
                          <li key={note.id}>
                            <div className="flex space-x-3">
                              <div className="flex-shrink-0">
                                <GoNote color="4F46E5" className="h-10 w-10" />
                              </div>
                              <div>
                                <div className="text-sm">
                                  <div className="font-medium text-gray-900">
                                    {note.noteFrom === ""
                                      ? "Employee"
                                      : note.noteFrom}
                                  </div>
                                </div>
                                <div className="mt-1 text-sm text-gray-700">
                                  <p>{note.noteBody}</p>
                                </div>
                                <div className="mt-2 text-sm space-x-2">
                                  <span className="text-gray-500 font-medium">
                                    {new Date(
                                      note.noteWhen.seconds
                                        ? note.noteWhen.seconds * 1000
                                        : note.noteWhen
                                    ).toDateString()}
                                  </span>{" "}
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-6 sm:px-6">
                    <div className="flex space-x-3">
                      <div className="min-w-0 flex-1">
                        <div>
                          <textarea
                            id="comment"
                            name="comment"
                            rows={3}
                            className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                            placeholder="Add a note"
                            defaultValue={""}
                            value={newNoteText}
                            onChange={(change) =>
                              setNewNoteText(change.target.value)
                            }
                          />
                        </div>
                        <div className="mt-3 flex items-center justify-end">
                          <button
                            onClick={() => handleAddNote()}
                            type="submit"
                            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Add Note
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <section
              aria-labelledby="timeline-title"
              className="lg:col-start-3 lg:col-span-1"
            >
              {/* Currently hidden at request of Tim */}
              {false && (
                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6 max-h-96">
                  <h2
                    id="timeline-title"
                    className="text-lg font-medium text-gray-900"
                  >
                    Pool Details
                  </h2>

                  {/* Pool Info */}
                  <div className="mt-6 flow-root max-h-64 overflow-y-scroll">
                    <ul role="list" className="divide-y divide-gray-200">
                      {poolDetails.map((detail) => (
                        <li key={detail.name} className="flex py-4">
                          <div className="ml-3">
                            <p className="text-sm font-medium text-gray-900">
                              {detail.name}
                            </p>
                            <p className="text-sm text-gray-500">
                              <input value={detail.value} />
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </section>
          </div>
        </main>
      </div>
      <ServiceLocationNewJob
        selectedBusiness={serviceLocation.businessId}
        setOpen={setNewJobDisplay}
        open={newJobDisplay}
        selectedServiceLocation={serviceLocation}
        selectedCustomer={props.selectedCustomer}
      />
      <ServiceLocationAddSubscription
        selectedBusiness={serviceLocation.businessId}
        setOpen={setAddSubscriptionDisplay}
        open={addSubscriptionDisplay}
        selectedServiceLocation={serviceLocation}
        selectedCustomer={props.selectedCustomer}
        handleReloadData={handleReloadData}
        subscriptionDetails={subscriptionDetails}
        pausedSubscriptions={pausedSubscriptions}
        routes={serviceLocation.routes}
        key={reload}
      />
      <ServiceLocationAddOneTimeStop
        selectedBusiness={serviceLocation.businessId}
        setOpen={setCreateOneTimeStopDisplay}
        open={createOneTimeStopDisplay}
        selectedServiceLocation={serviceLocation}
        selectedCustomer={props.selectedCustomer}
        handleReloadData={handleReloadData}
      />
    </>
  );
}
